import React, { useState, useEffect, useCallback } from 'react';
import { Switch, List, ListItem, ListItemIcon, ListItemText, Typography, Divider, Snackbar } from '@mui/material';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Atras from './atras';
import BottomToolbar from './BottomToolbar';
import { Container, Toolbar, AppBar } from '@mui/material';

const SettingNotification = () => {
  const [settings, setSettings] = useState({
    notificationsEnabled: false,
    locationEnabled: false,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const fetchUserSettings = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const notificationPermission = await checkNotificationPermission();
          const locationPermission = await checkLocationPermission();
          
          setSettings({
            notificationsEnabled: userData.notificationsEnabled && notificationPermission === 'granted',
            locationEnabled: userData.locationEnabled && locationPermission === 'granted',
          });
        }
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      showSnackbar('Error al cargar la configuración');
    }
  }, []);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  const updateSetting = async (setting, value) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, { [setting]: value });
        setSettings(prev => ({ ...prev, [setting]: value }));
        showSnackbar('Configuración actualizada');
      }
    } catch (error) {
      console.error(`Error updating ${setting} setting:`, error);
      showSnackbar(`Error al actualizar ${setting}`);
    }
  };

  const checkNotificationPermission = async () => {
    if (!('Notification' in window)) {
      return 'denied';
    }
    return await Notification.permission;
  };

  const checkLocationPermission = () => {
    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        resolve('denied');
      } else {
        navigator.geolocation.getCurrentPosition(
          () => resolve('granted'),
          () => resolve('denied')
        );
      }
    });
  };

  const handleNotificationsChange = async (event) => {
    const newStatus = event.target.checked;
    if (newStatus) {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        showSnackbar('Permiso de notificaciones denegado');
        return;
      }
    }
    updateSetting('notificationsEnabled', newStatus);
    // Sincronizar con el navegador
    if (newStatus && Notification.permission === 'granted') {
      // Aquí puedes configurar las notificaciones del navegador si es necesario
      showSnackbar('Notificaciones activadas en el navegador');
    } else if (!newStatus) {
      // Desactivar notificaciones en el navegador (si es posible)
      showSnackbar('Notificaciones desactivadas en el navegador');
    }
  };

  const handleLocationChange = async (event) => {
    const newStatus = event.target.checked;
    if (newStatus) {
      if (!navigator.geolocation) {
        showSnackbar('Geolocalización no soportada por este navegador');
        return;
      }
      try {
        const permission = await requestLocationPermission();
        if (permission !== 'granted') {
          showSnackbar('Permiso de ubicación denegado');
          return;
        }
        updateSetting('locationEnabled', true);
        showSnackbar('Ubicación activada en el navegador');
      } catch (error) {
        console.error('Error al obtener el permiso de ubicación:', error);
        showSnackbar('Error al activar la ubicación');
      }
    } else {
      updateSetting('locationEnabled', false);
      showSnackbar('Ubicación desactivada en el navegador');
    }
  };

  const requestLocationPermission = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        () => resolve('granted'),
        (error) => {
          console.error('Error getting location:', error);
          reject(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    });
  };

  const showSnackbar = (message) => {
    setSnackbar({ open: true, message });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const styles = {
    cont: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      height: '90px',
      marginTop: '10px',
      color: 'black',
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: '#312783' }}>
        <Toolbar
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color={'white'} gutterBottom>
              Configuración
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 16, mb: 16 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <Typography color={"black"}>Notificaciones</Typography>
            </ListItemIcon>
            <ListItemText/>
            <Switch
              checked={settings.notificationsEnabled}
              onChange={handleNotificationsChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Typography color={"black"}>Ubicación</Typography>
            </ListItemIcon>
            <ListItemText/>
            <Switch
              checked={settings.locationEnabled}
              onChange={handleLocationChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </ListItem>
          <Divider />
        </List>
      </Container>
      <BottomToolbar />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Container>
  );
};

export default SettingNotification;