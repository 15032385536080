import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  List,
  ListItem,
  CircularProgress,
  Box,
  CardMedia,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import MediaQuery from "react-responsive";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db, getToken, uploadImage } from "../firebase";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Atras from "./atras";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  AttachMoney as AttachMoneyIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import BottomToolbar from "./BottomToolbar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const EditEvents = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [uid, setUid] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [videos, setVideos] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("9:16"); // Valor predeterminado

  const handleLoadedMetadata = (e) => {
    const video = e.target;
    const ratio = video.videoWidth / video.videoHeight;
    setAspectRatio(ratio > 1 ? "16:9" : "9:16"); // Detecta si es 16:9 o 9:16
  };

  const handleVideoUpload = async (event) => {
    const files = event.target.files;
    if (!files) return;

    setLoading(true);
    const newVideos = [];

    try {
      for (const file of files) {
        const videoUrl = await uploadImage(file); // Suponiendo que `uploadImage` funciona para videos también
        newVideos.push(videoUrl);
      }
      setVideos((prevVideos) => [...prevVideos, ...newVideos]);
    } catch (error) {
      console.error("Error al cargar videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveVideo = (index) => {
    setVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (selectedEventId) {
      const fetchEventData = async () => {
        const eventRef = doc(db, "events", selectedEventId);
        const eventSnap = await getDoc(eventRef);
        if (eventSnap.exists()) {
          const eventData = eventSnap.data();
          setTitle(eventData.title || "");
          setDescription(eventData.description || "");
          setDate(eventData.date ? eventData.date.toDate() : new Date());
          setEventType(eventData.eventType || "");
          setImages(eventData.images || []);
          setVideos(eventData.videos || []);
        }
      };
      fetchEventData();
    }
  }, [selectedEventId]);

  const handleSubmit = async () => {
    if (
      !title ||
      !description ||
      !date ||
      (images.length === 0 && videos.length === 0)
    ) {
      alert("Por favor, incluye al menos una imagen o un video.");
      return;
    }

    if (!selectedEventId) {
      alert("Error: No se ha seleccionado ningún evento para actualizar.");
      return;
    }

    // Define `eventsRef` aquí usando el ID del evento seleccionado
    const eventsRef = doc(db, "events", selectedEventId);

    const updateData = {
      title,
      description,
      date,
      images,
      videos,
      eventType,
    };

    try {
      await updateDoc(eventsRef, updateData);
      handleClose();
    } catch (error) {
      console.error("Error updating:", error);
      alert("Error al actualizar.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (eventId) => {
    setSelectedEventId(eventId);
    setOpen(true);
  };

  const handleDelete = async (eventId) => {
    try {
      const eventRef = doc(db, "events", eventId);
      await deleteDoc(eventRef);
    } catch (error) {
      console.error("Error al eliminar la oferta:", error);
    }
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date()); // Initialize date state with current date
  const [eventType, setEventType] = useState("");

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state directly with the newDate
  };

  const [images, setImages] = useState([]);

  const handleImage = async (event) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    setLoading(true);

    try {
      const newImages = [];

      for (const file of files) {
        const imageUrl = await uploadImage(file);
        newImages.push(imageUrl);
      }

      setImages((prevImages) => [...prevImages, ...newImages]);
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const [events, setEvents] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = events.filter((offer) =>
      offer.name.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, events]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndFetchEvents = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();

        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);

        setLoading(true);
        const q = query(collection(db, "events"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const eventsData = snapshot.docs.map((doc) => {
            const data = doc.data();
            if (typeof data.name !== "string") {
              console.error("Unexpected data type for name field:", data.name);
              return { ...data, name: "" };
            }
            return {
              ...data,
              id: doc.id,
              createdAt: data.createdAt.toDate(),
              eventType: data.eventType || "",
            };
          });
          setEvents(eventsData);
          setLoading(false);
          console.log("Ofertas obtenidas:", eventsData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener ofertas:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchEvents();
  }, [navigate]);

  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const styles = {
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "100%",
      border: "none",
      margin: "16px auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 15px rgba(0,0,0,0.12)",
      },
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      marginRight: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "0px",
    },
    created: {
      color: "#757575",
      fontSize: "12px",
      marginTop: "12px",
      marginBottom: "16px",
    },
    media: {
      width: "190px",
      marginTop: "25px",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },
    mediaContainer: {
      marginBottom: "24px", // Añadir espacio debajo del contenedor de media
      borderRadius: "8px",
      overflow: "hidden",
    },
    contentContainer: {
      paddingLeft: { xs: "0", md: "24px" }, // Añadir padding en pantallas medianas y grandes
      flex: 1,
    },
    videoContainer: {
      position: "relative",
      width: "100%",
      marginBottom: "20px", // Añadir espacio debajo del contenedor de video
      borderRadius: "8px",
      overflow: "hidden",
    },
    infoSection: {
      marginTop: "16px", // Espacio arriba de la sección de información
      marginBottom: "16px", // Espacio abajo de la sección de información
    },
    title: {
      color: "#00897b",
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "16px", // Aumentar el espacio después del título
      lineHeight: 1.4,
    },
    description: {
      color: "#4a4a4a",
      fontSize: "15px",
      lineHeight: 1.6,
      marginBottom: "20px", // Aumentar el espacio después de la descripción
    },
    event: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2c2c2c",
      marginBottom: "16px", // Aumentar el espacio después del tipo de evento
      padding: "8px 12px",
      backgroundColor: "#f8f9fa",
      borderRadius: "8px",
    },
    date: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      color: "#1976d2",
      fontSize: "16px",
      fontWeight: "500",
      marginBottom: "20px", // Aumentar el espacio después de la fecha
      padding: "8px 12px",
      backgroundColor: "#e3f2fd",
      borderRadius: "8px",
    },
    buttonContainer: {
      marginTop: "16px", // Espacio arriba de los botones
      display: "flex",
      flexDirection: "column",
      gap: "12px", // Espacio entre botones
    },
    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
    imagePreview: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
      borderRadius: "10px",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color={"white"} gutterBottom>
              Publicaciones
            </Typography>
          </Container>
        </Toolbar>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "space-around",
            color: "black",
            borderTop: "1.5px solid white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editOffers" sx={{ color: "white" }}>
              <AttachMoneyIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar ofertas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editEvents" sx={{ color: "white" }}>
              <EventIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar eventos
            </Typography>
          </div>
        </Toolbar>
        <Toolbar sx={styles.search}>
          <input
            type="text"
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
            style={styles.inputS}
            placeholder="Buscar por nombre de evento..."
          />
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 33, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {resultado.length > 0 ? (
                <List>
                  {resultado.map((selectedEvent) => (
                    <ListItem key={selectedEvent.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={selectedEvent.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography style={styles.name}>
                          {selectedEvent.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                          mt: 3, // Espacio después del header
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "20%" },
                            alignItems: { xs: "center", md: "flex-start" },
                            mb: { xs: 3, md: 0 },
                          }}
                        >
                          {selectedEvent.videos &&
                          selectedEvent.videos.length > 0 ? (
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                paddingBottom:
                                  aspectRatio === "16:9" ? "56.25%" : "177.78%",
                                backgroundColor: "#000",
                                overflow: "hidden",
                                borderRadius: "5px",
                                marginBottom: "24px", // Espacio después del video
                              }}
                            >
                              <video
                                src={selectedEvent.videos[0]}
                                controls
                                muted
                                playsInline
                                autoPlay
                                onLoadedMetadata={handleLoadedMetadata}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover", // Ajusta el video sin distorsión
                                }}
                              />
                            </div>
                          ) : selectedEvent.images &&
                            selectedEvent.images.length > 0 ? (
                            <Box style={styles.imageContainer}>
                              <CardMedia
                                component="img"
                                image={selectedEvent.images[0]}
                                alt={`Imagen principal de ${selectedEvent.name}`}
                                style={styles.mainImage}
                              />
                              {selectedEvent.images.length > 1 && (
                                <Box
                                  style={styles.additionalImagesOverlay}
                                  onClick={() =>
                                    handleOpenImageModal(selectedEvent.images)
                                  }
                                >
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{selectedEvent.images.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>No hay medios disponibles</Typography>
                            </Box>
                          )}
                        </Box>

                        <Box
                          sx={{
                            width: { xs: "100%", md: "70%" },
                            pl: { xs: 0, md: 3 },
                          }}
                        >
                          <Typography style={styles.title}>
                            Titúlo: {selectedEvent.title}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={styles.description}
                            multiline
                          >
                            Descripción: {selectedEvent.description}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={styles.event}
                            multiline
                          >
                            Tipo de evento: {selectedEvent.eventType}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={styles.date}
                          >
                            Fecha: {formatDate(selectedEvent.date)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ fontSize: "10px" }}
                            style={styles.created}
                          >
                            Fecha de publicación:{" "}
                            {formatDate(selectedEvent.createdAt)}
                          </Typography>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => handleEditClick(selectedEvent.id)}
                          >
                            <Typography>Editar</Typography>
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ mt: 2 }}
                            fullWidth
                            onClick={() => handleDelete(selectedEvent.id)} // Llamamos a la función de eliminación con el ID correspondiente
                          >
                            <Typography>Eliminar</Typography>
                          </Button>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {events.map((events) => (
                    <ListItem key={events.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={events.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography
                          variant="h5"
                          sx={{ color: "#312783" }}
                          style={styles.typography}
                        >
                          {events.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: "100%",
                          mt: 3, // Añadir espacio arriba del contenedor principal
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "100%", md: "40%" },
                            mb: { xs: 3, md: 0 },
                          }}
                        >
                          {events.videos && events.videos.length > 0 ? (
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                paddingBottom:
                                  aspectRatio === "16:9" ? "56.25%" : "177.78%",
                                backgroundColor: "#000",
                                overflow: "hidden",
                                borderRadius: "8px",
                              }}
                            >
                              <video
                                src={events.videos[0]}
                                controls
                                muted
                                playsInline
                                autoPlay
                                onLoadedMetadata={handleLoadedMetadata}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover", // Ajusta el video sin distorsión
                                }}
                              />
                            </div>
                          ) : events.images && events.images.length > 0 ? (
                            <Box style={styles.imageContainer}>
                              <CardMedia
                                component="img"
                                image={events.images[0]}
                                alt={`Imagen principal de ${events.name}`}
                                style={styles.mainImage}
                              />
                              {events.images.length > 1 && (
                                <Box
                                  style={styles.additionalImagesOverlay}
                                  onClick={() =>
                                    handleOpenImageModal(events.images)
                                  }
                                >
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{events.images.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                          <Box
                            sx={{
                              width: { xs: "100%", md: "55%" },
                              pl: { xs: 0, md: 3 },
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              style={styles.typography}
                            >
                              {events.title}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={styles.typography}
                            >
                              {events.description}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={styles.typography}
                            >
                              {events.eventType}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={styles.date}
                            >
                              Fecha: {formatDate(events.date)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ fontSize: "10px" }}
                              style={styles.created}
                            >
                              Fecha de publicación:{" "}
                              {formatDate(events.createdAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose} boxSizing>
        <DialogTitle>Publicar Evento</DialogTitle>
        <DialogContent>
          <MediaQuery minWidth={1224}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="title"
              label="Nombre del evento"
              type="text"
              fullWidth
              value={title}
              onChange={handleTitle}
            />
            <TextField
              required
              margin="dense"
              id="description"
              label="Descripción del evento"
              type="text"
              multiline
              rows={2}
              fullWidth
              value={description}
              onChange={handleDescription}
            />
            <DatePicker
              required
              margin="dense"
              label="Fecha MM/dd/yyyy"
              format="MM/dd/yyyy"
              sx={{ mt: 2 }}
              onChange={handleDateChange}
              value={date}
            />
            <Typography>Tipo de evento:</Typography>
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <InputLabel id="event-type-label">Tipo de evento</InputLabel>
              <Select
                labelId="event-type-label"
                id="event-type"
                value={eventType}
                label="Tipo de evento"
                onChange={handleEventTypeChange}
              >
                <MenuItem value="exposicion">Exposición</MenuItem>
                <MenuItem value="innauguracion">Inauguración</MenuItem>
                <MenuItem value="feriado">Feriado</MenuItem>
                <MenuItem value="concierto">Concierto</MenuItem>
                <MenuItem value="festival">Festival</MenuItem>
                <MenuItem value="obraDeTeatro">Obra de teatro</MenuItem>
                <MenuItem value="conferencia">Conferencia</MenuItem>
                <MenuItem value="otro">Otro</MenuItem>
              </Select>
            </FormControl>

            {/* Botón para imágenes */}
            <label
              htmlFor="upload-image-button"
              style={{ width: "100%", marginTop: "15px" }}
            >
              <Button
                variant="contained"
                fullWidth
                component="div"
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black", mr: 1 }} />
                Subir Imágenes
              </Button>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImage}
              style={{ display: "none" }}
              id="upload-image-button"
              multiple
            />

            {/* Botón para videos */}
            <label
              htmlFor="upload-video-button"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <Button
                variant="contained"
                fullWidth
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black", mr: 1 }} />
                Subir Videos
              </Button>
            </label>
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoUpload} // Nueva función de manejo de videos
              style={{ display: "none" }}
              id="upload-video-button"
              multiple
            />

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={imageUrl}
                      alt={`Imagen ${index + 1}`}
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {videos.map((videoUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ position: "relative" }}>
                    <video
                      src={videoUrl}
                      controls
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveVideo(index)} // Nueva función para remover videos
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
          <MediaQuery maxWidth={1223}>
            <TextField
              required
              autoFocus
              margin="dense"
              id="title"
              label="Nombre del evento"
              type="text"
              fullWidth
              value={title}
              onChange={handleTitle}
            />
            <TextField
              required
              margin="dense"
              id="description"
              label="Descripción del evento"
              type="text"
              multiline
              rows={2}
              fullWidth
              value={description}
              onChange={handleDescription}
            />
            <DatePicker
              required
              margin="dense"
              label="Fecha MM/dd/yyyy"
              format="MM/dd/yyyy"
              sx={{ mt: 2 }}
              onChange={handleDateChange}
              value={date}
            />
            <Typography>Tipo de evento:</Typography>
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <InputLabel id="event-type-label">Tipo de evento</InputLabel>
              <Select
                labelId="event-type-label"
                id="event-type"
                value={eventType}
                label="Tipo de evento"
                onChange={handleEventTypeChange}
              >
                <MenuItem value="exposicion">Exposición</MenuItem>
                <MenuItem value="innauguracion">Inauguración</MenuItem>
                <MenuItem value="feriado">Feriado</MenuItem>
                <MenuItem value="concierto">Concierto</MenuItem>
                <MenuItem value="festival">Festival</MenuItem>
                <MenuItem value="obraDeTeatro">Obra de teatro</MenuItem>
                <MenuItem value="conferencia">Conferencia</MenuItem>
                <MenuItem value="otro">Otro</MenuItem>
              </Select>
            </FormControl>

            {/* Botón para imágenes */}
            <label
              htmlFor="upload-image-button"
              style={{ width: "100%", marginTop: "15px" }}
            >
              <Button
                variant="contained"
                fullWidth
                component="div"
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black", mr: 1 }} />
                Subir Imágenes
              </Button>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImage}
              style={{ display: "none" }}
              id="upload-image-button"
              multiple
            />

            {/* Botón para videos */}
            <label
              htmlFor="upload-video-button"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <Button
                variant="contained"
                fullWidth
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
                disabled={loading}
              >
                <AddPhotoAlternateIcon sx={{ color: "black", mr: 1 }} />
                Subir Videos
              </Button>
            </label>
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoUpload} // Nueva función de manejo de videos
              style={{ display: "none" }}
              id="upload-video-button"
              multiple
            />

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={imageUrl}
                      alt={`Imagen ${index + 1}`}
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {videos.map((videoUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ position: "relative" }}>
                    <video
                      src={videoUrl}
                      controls
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveVideo(index)} // Nueva función para remover videos
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </MediaQuery>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        fullScreen // Make the modal full screen
      >
        <DialogTitle id="image-modal-title">Imágenes del evento</DialogTitle>
        <DialogContent>
          <DialogContentText id="image-modal-description">
            {openImageModal && (
              <Box style={styles.imageModalContent}>
                {modalImages && modalImages.length > 0 ? (
                  <CardMedia
                    component="img"
                    image={modalImages[currentImageIndex]}
                    alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                    style={styles.imageModalImage}
                  />
                ) : (
                  <Typography>No hay imágenes disponibles</Typography>
                )}
                <Box style={styles.imageModalNavigation}>
                  <IconButton onClick={handlePreviousImage}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton onClick={handleNextImage}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <BottomToolbar />
    </Container>
  );
};

export default EditEvents;
