import React, { useState, useEffect } from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isLoggedIn } from "../../session";
import { useNavigate } from "react-router-dom";
import Atras from "../atras";

function Header() {
  const { placeId } = useParams();
  const [placeName, setPlaceName] = useState(""); // Corrected state name

  useEffect(() => {
    const fetchPlaceData = async () => {
      try {
        const db = getFirestore();
        const placeDocRef = doc(db, "places", placeId);
        const placeDocSnapshot = await getDoc(placeDocRef);

        if (placeDocSnapshot.exists()) {
          const placeData = placeDocSnapshot.data();
          const name = placeData.title || "Lugar"; // Adjusted to match your data structure
          setPlaceName(name);
        } else {
          console.error("No se encontro el lugar");
        }
      } catch (error) {
        console.error("Error fetching place data:", error);
      }
    };

    if (placeId) {
      fetchPlaceData();
    }
  }, [placeId]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const styles = {
    appBar: {
      bgcolor: "#312783",
    },
  }

  return (
    <AppBar position="fixed" sx={styles.appBar}>
      <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", height: "100%" }}>
        <Atras />
        {placeName && (
          <Typography
            variant="h6"
            sx={{ paddingLeft: "24px", paddingTop: "10px", fontSize: "24px", color: "white" }}
            gutterBottom
          >
            {placeName}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
