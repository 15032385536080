import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase"; // Make sure to configure your firebase file
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import {
  Button,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
  Grid,
  Container,
  AppBar,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import Atras from "../atras";
import BottomNav from "./BottomNav";

const CONTINENTS = ["Asia", "América del Norte", "América del Sur", "Europa"];

const TripsComponent = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedContinent, setSelectedContinent] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return !!auth.currentUser;
  };

  const getToken = async () => {
    if (auth.currentUser) {
      return auth.currentUser.getIdToken();
    }
    return null;
  };

  useEffect(() => {
    const checkAuthAndFetchPlaces = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }

        setLoading(true);
        const { uid } = currentUser;

        // Query the 'trips' collection for trips matching only the uid
        const tripsQuery = query(
          collection(db, "trips"),
          where("uid", "==", uid)
        );
        const tripsSnapshot = await getDocs(tripsQuery);

        const placePromises = tripsSnapshot.docs.map(async (tripDoc) => {
          const tripData = tripDoc.data();
          const placeRef = doc(db, "places", tripData.viajeId); // Assuming 'placeId' in 'trips' refers to the place ID
          const placeDoc = await getDoc(placeRef);

          if (placeDoc.exists()) {
            const placeData = placeDoc.data();
            let imageUrls = [];
            if (placeData.images && Array.isArray(placeData.images)) {
              imageUrls = placeData.images;
            } else if (
              placeData.images &&
              typeof placeData.images === "string"
            ) {
              imageUrls = [placeData.images];
            }
            return {
              ...placeData,
              id: placeDoc.id,
              imageUrls: imageUrls,
              createdAt: placeData.createdAt.toDate(),
            };
          } else {
            console.log("Place document not found for trip:", tripDoc.id);
            return null; // Or handle the case where the place is not found
          }
        });

        const placesData = await Promise.all(placePromises);
        setPlaces(placesData.filter((place) => place !== null)); // Filter out any null values

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener places:", error);
        setLoading(false);
      }
    };

    checkAuthAndFetchPlaces();
  }, [navigate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleContinentChange = (e) => {
    setSelectedContinent(e.target.value);
  };

  const filteredPlaces = places.filter((place) => {
    const searchMatch =
      place.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      place.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      place.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      place.price.toString().includes(searchTerm) ||
      place.place.toLowerCase().includes(searchTerm.toLowerCase());

    return searchMatch;
  });

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleVerClick = (placeId) => {
    navigate(`/viajes/ver/${placeId}`);
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "white",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "0px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "20px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "95vw",
      marginBottom: "5px",
    },
    card: {
      border: "2px solid grey",
      borderRadius: "10px",
      padding: "20px",
    },
    carde: {
      width: "100%",
      border: "1px solid #ddd",
      borderRadius: "8px",
      overflow: "hidden",
      marginBottom: "20px",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "300px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "80px",
      height: "80px",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    contentContainer: {
      padding: "16px",
    },
    name: {
      fontSize: "14px",
      color: "#777",
      marginBottom: "8px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    media: {
      width: "100px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
  };

  return (
    <Container>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          marginBottom: "10px",
          bgcolor: "white",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            height: "auto",
          }}
        >
          <Atras />
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, ml: 3 }}
              color="black"
            >
              Mis Viajes
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar>
          <Box sx={styles.buscar}>
            <TextField
              label="Buscar en mis viajes..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              sx={{ color: "black" }}
            />
            <TextField
              select
              label="Continente"
              value={selectedContinent}
              onChange={handleContinentChange}
              variant="outlined"
              fullWidth
              sx={{ color: "black", ml: 1 }}
            >
              <MenuItem value="">Todos</MenuItem>
              {CONTINENTS.map((continent) => (
                <MenuItem key={continent} value={continent}>
                  {continent}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 23 }}>
        <Box>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {filteredPlaces.length > 0 ? (
                <Grid container spacing={2} sx={{ mb: 20 }}>
                  {filteredPlaces.map((place) => (
                    <Grid item key={place.id} xs={12} sm={6} md={4} lg={4}>
                      <Box style={styles.card}>
                        <Typography style={styles.title}>
                          {place.title}
                        </Typography>

                        <Typography style={styles.description}>
                          {place.description}
                        </Typography>
                        <Typography style={styles.price}>
                          Precio por día: $ {place.price}
                        </Typography>
                        <Typography style={styles.place}>
                          Ciudad: {place.city}
                        </Typography>
                        <Typography style={styles.place}>
                          Lugar: {place.place}
                        </Typography>
                        <Typography style={styles.place}>
                          Categoria: {place.category}
                        </Typography>
                        <Box style={styles.imageContainer}>
                          {place.imageUrls && place.imageUrls.length > 0 ? (
                            <>
                              <CardMedia
                                component="img"
                                image={place.imageUrls[0]}
                                alt={`Imagen principal de ${place.name}`}
                                style={styles.mainImage}
                              />
                              {place.imageUrls.length > 1 && (
                                <Box style={styles.additionalImagesOverlay}>
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{place.imageUrls.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Button
                          variant="contained"
                          onClick={() => handleVerClick(place.id)}
                          fullWidth
                          sx={{ mt: 1, mb: 1 }}
                        >
                          Volver a contactar
                        </Button>
                        <Typography style={styles.created}>
                          Fecha de publicación: {formatDate(place.createdAt)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>
                  No se haz contratado ningún alojamiento aún.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Container>
      <BottomNav />
    </Container>
  );
};

export default TripsComponent;
