import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Grid,
  Alert,
  FormHelperText,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { auth, db, uploadImage } from "../../firebase";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import Atras from "../atras";
import { InputAdornment } from "@mui/material";
import { useSubscriptionContext } from "../SubscriptionContext";

const CAR_FORM_VALIDATION = {
  title: {
    required: true,
    minLength: 5,
    maxLength: 100,
    validate: (value) => {
      if (!value) return "El título es obligatorio";
      if (value.length < 5) return "El título debe tener al menos 5 caracteres";
      if (value.length > 100)
        return "El título no puede exceder 100 caracteres";
      return "";
    },
  },
  description: {
    required: true,
    minLength: 20,
    maxLength: 1000,
    validate: (value) => {
      if (!value) return "La descripción es obligatoria";
      if (value.length < 20)
        return "La descripción debe tener al menos 20 caracteres";
      if (value.length > 1000)
        return "La descripción no puede exceder 1000 caracteres";
      return "";
    },
  },
  price: {
    required: true,
    validate: (value) => {
      if (!value) return "El precio es obligatorio";
      if (isNaN(value) || value <= 0)
        return "El precio debe ser un número válido mayor que 0";
      return "";
    },
  },
  city: {
    required: true,
    validate: (value) => {
      if (!value) return "La ciudad es obligatoria";
      if (value.length < 2) return "El nombre de la ciudad es demasiado corto";
      return "";
    },
  },
  marca: {
    required: true,
    validate: (value) => {
      if (!value) return "La marca es obligatoria";
      return "";
    },
  },
  modelo: {
    required: true,
    validate: (value) => {
      if (!value) return "El modelo es obligatorio";
      if (value.length < 2) return "El modelo debe tener al menos 2 caracteres";
      return "";
    },
  },
  año: {
    required: true,
    validate: (value) => {
      if (!value) return "El año es obligatorio";
      const year = parseInt(value);
      const currentYear = new Date().getFullYear();
      if (isNaN(year) || year < 1900 || year > currentYear + 1)
        return `El año debe estar entre 1900 y ${currentYear + 1}`;
      return "";
    },
  },
  km: {
    required: true,
    validate: (value) => {
      if (!value) return "El kilometraje es obligatorio";
      if (isNaN(value) || value < 0)
        return "El kilometraje debe ser un número válido";
      return "";
    },
  },
  condition: {
    required: true,
    validate: (value) => {
      if (!value) return "La condición es obligatoria";
      const validConditions = ["Nuevo", "Usado", "Reparar"];
      if (!validConditions.includes(value))
        return "Seleccione una condición válida";
      return "";
    },
  },
  images: {
    required: true,
    validate: (value) => {
      if (!Array.isArray(value) || value.length === 0)
        return "Debe subir al menos una imagen";
      if (value.length > 10) return "No puede subir más de 10 imágenes";
      return "";
    },
  },
};

const PublishCar = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    city: "",
    marca: "",
    modelo: "",
    año: "",
    km: "",
    condition: "",
    timeToDo: new Date(),
    images: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const navigate = useNavigate();
  const { subscription } = useSubscriptionContext();

  useEffect(() => {
    if (!subscription) {
      navigate('/subscription'); // Redirige si no hay suscripción activa
    }
  }, [subscription, navigate]);

  // Función para validar un campo específico
  const validateField = (name, value) => {
    const fieldValidation = CAR_FORM_VALIDATION[name];
    if (!fieldValidation) return "";
    return fieldValidation.validate(value);
  };

  // Validar todo el formulario
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    Object.keys(CAR_FORM_VALIDATION).forEach((fieldName) => {
      const error = validateField(fieldName, formData[fieldName]);
      if (error) {
        errors[fieldName] = error;
        isValid = false;
      }

      if (!formData.price) {
        errors.price = "El precio es requerido";
        isValid = false;
      } else {
        const price = parseFloat(formData.price);
        if (isNaN(price)) {
          errors.price = "Ingrese un precio válido";
          isValid = false;
        } else if (price <= 0) {
          errors.price = "El precio debe ser mayor a 0";
          isValid = false;
        } else if (price > 999999.99) {
          errors.price = "El precio no puede exceder 999,999.99";
          isValid = false;
        }
      }
    });

    return { errors, isValid };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (submitAttempted) {
      const error = validateField(name, value);
      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    if (!files.length) return;

    if (formData.images.length + files.length > 10) {
      setFormErrors((prev) => ({
        ...prev,
        images: "No puede subir más de 10 imágenes",
      }));
      return;
    }

    setLoading(true);

    try {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...imageUrls],
      }));

      // Validar imágenes después de la actualización
      const error = validateField("images", [...formData.images, ...imageUrls]);
      setFormErrors((prev) => ({
        ...prev,
        images: error,
      }));
    } catch (error) {
      setFormErrors((prev) => ({
        ...prev,
        images: "Error al subir las imágenes",
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = formData.images.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      images: updatedImages,
    }));

    if (submitAttempted) {
      const error = validateField("images", updatedImages);
      setFormErrors((prev) => ({
        ...prev,
        images: error,
      }));
    }
  };

  const handlePublishCar = async () => {
    setSubmitAttempted(true);
    const { errors, isValid } = validateForm();

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    try {
      setLoading(true);
      const { uid } = auth.currentUser || {};

      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        throw new Error("Usuario no encontrado");
      }

      const userData = userDocSnapshot.data();
      await addDoc(collection(db, "cars"), {
        name: userData.displayName,
        ...formData,
        createdAt: serverTimestamp(),
        uid,
      });

      setPublishSuccess(true);
    } catch (error) {
      setFormErrors((prev) => ({
        ...prev,
        submit: "Error al publicar el vehículo: " + error.message,
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleRedirectHome = () => {
    navigate("/home");
  };

  // Los estilos se mantienen igual...

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Vehiculo
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 14, mb: 20 }}>
        {formErrors.submit && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {formErrors.submit}
          </Alert>
        )}

        <TextField
          autoFocus
          margin="dense"
          name="title"
          label="Título"
          type="text"
          fullWidth
          value={formData.title}
          onChange={handleInputChange}
          error={!!formErrors.title}
          helperText={formErrors.title}
          required
        />

        <TextField
          margin="dense"
          name="description"
          label="Características del vehículo"
          type="text"
          fullWidth
          multiline
          rows={5}
          value={formData.description}
          onChange={handleInputChange}
          error={!!formErrors.description}
          helperText={formErrors.description}
          required
        />
        <TextField
          margin="dense"
          id="price"
          label="Precio en USD"
          type="text"
          fullWidth
          required
          name="price"
          value={formData.price}
          onChange={(e) => {
            const value = e.target.value;

            // Solo permite números y un punto decimal
            if (/^\d*\.?\d*$/.test(value)) {
              // Si hay punto decimal, limita a 2 decimales
              if (value.includes(".")) {
                const [whole, decimal] = value.split(".");
                const formattedValue = `${whole}.${decimal.slice(0, 2)}`;
                setFormData((prev) => ({
                  ...prev,
                  price: formattedValue,
                }));
              } else {
                setFormData((prev) => ({
                  ...prev,
                  price: value,
                }));
              }
            }
          }}
          onBlur={(e) => {
            // Al perder el foco, asegura formato con 2 decimales
            const value = e.target.value;
            if (value) {
              const numberValue = parseFloat(value);
              if (!isNaN(numberValue)) {
                setFormData((prev) => ({
                  ...prev,
                  price: numberValue.toFixed(2),
                }));
              }
            }
          }}
          error={!!formErrors.price}
          helperText={formErrors.price}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />

        <TextField
          margin="dense"
          name="modelo"
          label="Modelo"
          type="text"
          fullWidth
          value={formData.modelo}
          onChange={handleInputChange}
          error={!!formErrors.modelo}
          helperText={formErrors.modelo}
          required
        />

        <TextField
          margin="dense"
          name="año"
          label="Año"
          type="number"
          fullWidth
          value={formData.año}
          onChange={handleInputChange}
          error={!!formErrors.año}
          helperText={formErrors.año}
          required
        />

        <TextField
          margin="dense"
          name="km"
          label="Kilometraje"
          type="number"
          fullWidth
          value={formData.km}
          onChange={handleInputChange}
          error={!!formErrors.km}
          helperText={formErrors.km}
          required
        />

        <Typography sx={{ mt: 2 }}>Marca:</Typography>
        <Select
          name="marca"
          value={formData.marca}
          onChange={handleInputChange}
          fullWidth
          error={!!formErrors.marca}
          required
        >
          <MenuItem value="" disabled>
            Selecciona una marca
          </MenuItem>
          {[
            "Alfa Romeo",
            "Audi",
            "BMW",
            "Chevrolet",
            "Dodge",
            "Ferrari",
            "Fiat",
            "Ford",
            "Honda",
            "Hyundai",
            "Jeep",
            "Mercedez Benz",
            "Reanult",
            "Volkswagen",
          ].map((marca) => (
            <MenuItem key={marca} value={marca}>
              {marca}
            </MenuItem>
          ))}
        </Select>
        {formErrors.marca && (
          <FormHelperText error>{formErrors.marca}</FormHelperText>
        )}

        <Typography sx={{ mt: 2 }}>Condición del vehículo:</Typography>
        <Select
          name="condition"
          value={formData.condition}
          onChange={handleInputChange}
          fullWidth
          error={!!formErrors.condition}
          required
        >
          <MenuItem value="" disabled>
            Selecciona la condición
          </MenuItem>
          <MenuItem value="Nuevo">Nuevo</MenuItem>
          <MenuItem value="Usado">Usado</MenuItem>
          <MenuItem value="Reparar">Para reparar</MenuItem>
        </Select>
        {formErrors.condition && (
          <FormHelperText error>{formErrors.condition}</FormHelperText>
        )}

        <TextField
          margin="dense"
          name="city"
          label="Ciudad"
          type="text"
          fullWidth
          value={formData.city}
          onChange={handleInputChange}
          error={!!formErrors.city}
          helperText={formErrors.city}
          required
        />

        {/* Sección de imágenes */}
        {(formErrors?.images || formData.images.length === 0) && (
          <Alert
            severity={formErrors?.images ? "error" : "info"}
            sx={{ mt: 2, mb: 1 }}
          >
            {formErrors?.images ||
              "Debe subir al menos una imagen del vehículo"}
          </Alert>
        )}

        <label htmlFor="upload-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            component="div"
            sx={{
              ...styles.uploadButton,
              border: formErrors?.images
                ? "2px solid #d32f2f"
                : "2px solid #312783",
            }}
            disabled={loading || formData.images.length >= 10}
          >
            <AddPhotoAlternateIcon sx={{ color: "black" }} />
            {formData.images.length >= 10
              ? "Límite de imágenes alcanzado"
              : `Subir imagen${
                  formData.images.length > 0
                    ? ` (${formData.images.length}/10)`
                    : "es"
                }`}
            {loading && <CircularProgress size={24} sx={{ ml: 1 }} />}
          </Button>
        </label>

        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="upload-button"
          multiple
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {formData.images.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  paddingTop: "75%",
                  border: formErrors?.images
                    ? "2px solid #d32f2f"
                    : "2px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={imageUrl}
                  alt={`Imagen ${index + 1}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveImage(index)}
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    minWidth: "36px",
                    width: "36px",
                    height: "36px",
                    borderRadius: "50%",
                    p: 0,
                  }}
                >
                  X
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Sección de éxito y botones */}
        {publishSuccess ? (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Alert severity="success" sx={{ mb: 2 }}>
              El vehículo se ha publicado correctamente
            </Alert>
            <Button
              variant="contained"
              onClick={handleRedirectHome}
              color="primary"
              sx={{ mt: 2 }}
            >
              Ir a Inicio
            </Button>
          </Box>
        ) : (
          <Box sx={{ mt: 3 }}>
            {submitAttempted && Object.keys(formErrors).length > 0 && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Por favor, corrija los errores antes de publicar
              </Alert>
            )}
            <Button
              variant="contained"
              fullWidth
              onClick={handlePublishCar}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
              Publicar Vehículo
            </Button>
          </Box>
        )}
      </Container>
    </Container>
  );
};

// Estilos
const styles = {
  uploadButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50px",
    backgroundColor: "#f0f0f0",
    marginTop: "20px",
    borderRadius: "8px",
    color: "black",
  },
  progress: {
    position: "absolute",
    color: "#312783",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "5px",
  },
  removeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    minWidth: "36px",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    padding: 0,
  },
};

export default PublishCar;
