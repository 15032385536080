import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase"; // Asegúrate de configurar tu archivo firebase
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import {
  Button,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
  Grid,
  Container,
  AppBar,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import Atras from "../atras";
import BottomNav from "./BottomNav";

const Marcas = [
  "Alfa Romeo",
  "Audi",
  "BMW",
  "Chevrolet",
  "Dodge",
  "Ferrari",
  "Fiat",
  "Ford",
  "Honda",
  "Hyundai",
  "Jeep",
  "Mercedes Benz",
  "Renault",
  "Volkswagen",
];

const VehiculosComponent = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return !!auth.currentUser;
  };

  const getToken = async () => {
    if (auth.currentUser) {
      return auth.currentUser.getIdToken();
    }
    return null;
  };

  useEffect(() => {
    const checkAuthAndFetchVehiculos = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }

        setLoading(true);
        const { uid } = currentUser;

        // Query the 'vehiculos' collection for entries matching the user's uid
        const vehiculosQuery = query(
          collection(db, "vehiculos"),
          where("uid", "==", uid)
        );
        const vehiculosSnapshot = await getDocs(vehiculosQuery);

        const vehiculoPromises = vehiculosSnapshot.docs.map(
          async (vehiculoDoc) => {
            const vehiculoData = vehiculoDoc.data();
            const carRef = doc(db, "cars", vehiculoData.autoId); // Assuming 'vehiculoId' in 'vehiculos' refers to the car ID
            const carDoc = await getDoc(carRef);

            if (carDoc.exists()) {
              const carData = carDoc.data();
              let imageUrls = [];
              if (carData.images && Array.isArray(carData.images)) {
                imageUrls = carData.images;
              } else if (carData.images && typeof carData.images === "string") {
                imageUrls = [carData.images];
              }
              return {
                ...carData,
                id: carDoc.id,
                imageUrls: imageUrls,
                createdAt: carData.createdAt.toDate(),
              };
            } else {
              console.log(
                "Car document not found for vehiculo:",
                vehiculoDoc.id
              );
              return null; // Or handle the case where the car is not found
            }
          }
        );

        const vehiculosData = await Promise.all(vehiculoPromises);
        setCars(vehiculosData.filter((car) => car !== null)); // Filter out any null values

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener vehiculos:", error);
        setLoading(false);
      }
    };

    checkAuthAndFetchVehiculos();
  }, [navigate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMarcaChange = (e) => {
    setSelectedMarca(e.target.value);
  };

  const filteredVehiculos = cars.filter((car) => {
    const searchMatch =
      car.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      car.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      car.price.toString().includes(searchTerm) ||
      car.city.toString().includes(searchTerm) ||
      car.modelo.toString().includes(searchTerm) ||
      car.km.toString().includes(searchTerm) ||
      car.marca.toString().toLowerCase().includes(searchTerm.toLowerCase());

    const brandMatch =
      selectedMarca === "" ||
      car.marca.toLowerCase().includes(selectedMarca.toLowerCase());

    return searchMatch && brandMatch;
  });

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleVerClick = (carId) => {
    navigate(`/autos/ver/${carId}`);
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "white",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "0px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "20px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "95vw",
      marginBottom: "5px",
    },
    card: {
      border: "2px solid grey",
      borderRadius: "10px",
      padding: "20px",
    },
    carde: {
      width: "100%",
      border: "1px solid #ddd",
      borderRadius: "8px",
      overflow: "hidden",
      marginBottom: "20px",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "300px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "80px",
      height: "80px",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    contentContainer: {
      padding: "16px",
    },
    name: {
      fontSize: "14px",
      color: "#777",
      marginBottom: "8px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    media: {
      width: "100px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    thumbnailContainer: {
      position: "relative", // To position the counter
      marginTop: "15px",
      marginBottom: "15px",
    },
    thumbnail: {
      width: "100%",
      height: "200px", // Adjust height as needed
      objectFit: "cover",
    },
    imageCount: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "white",
      padding: "10px 8px",
      borderRadius: "4px",
    },
  };

  return (
    <Container>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          marginBottom: "10px",
          bgcolor: "white",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            height: "auto",
          }}
        >
          <Atras />
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, ml: 3 }}
              color="black"
            >
              Mis Vehículos
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar>
          <Box sx={styles.buscar}>
            <TextField
              label="Buscar vehículo..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              select
              label="Marca"
              value={selectedMarca}
              onChange={handleMarcaChange}
              variant="outlined"
              fullWidth
              sx={{ ml: 1 }}
            >
              <MenuItem value="">Todos</MenuItem>
              {Marcas.map((marca) => (
                <MenuItem key={marca} value={marca}>
                  {marca}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 23 }}>
        <Box>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {filteredVehiculos.length > 0 ? (
                <Grid container spacing={2} sx={{ mb: 20 }}>
                  {filteredVehiculos.map((car) => (
                    <Grid item key={car.id} xs={12} sm={6} md={4} lg={4}>
                      <Box style={styles.card}>
                        <Typography sx={styles.title}>{car.title}</Typography>
                        <Box sx={styles.contentContainer}>
                          <Typography sx={styles.description}>
                            Condicion: {car.condicion}
                          </Typography>
                          <Typography sx={styles.description}>
                            {car.description}
                          </Typography>
                          <Typography sx={styles.price}>
                            Precio: $ {car.price}
                          </Typography>
                          <Typography sx={styles.modelo}>
                            Ciudad: {car.city}
                          </Typography>
                          <Typography sx={styles.modelo}>
                            Modelo: {car.año}
                          </Typography>
                          <Typography sx={styles.modelo}>
                            Kilómetros: {car.km} km
                          </Typography>
                          <Typography sx={styles.marca}>
                            Marca: {car.marca}
                          </Typography>
                          <Typography sx={styles.marca}>
                            Linea: {car.modelo}
                          </Typography>
                          <Typography sx={styles.created}>
                            Fecha de publicación: {formatDate(car.createdAt)}
                          </Typography>
                          <Box sx={styles.thumbnailContainer}>
                            {car.imageUrls && car.imageUrls.length > 0 ? (
                              <>
                                <CardMedia
                                  component="img"
                                  image={car.imageUrls[0]} // Display the first image
                                  alt={`Imagen de ${car.title}`}
                                  sx={styles.thumbnail}
                                />
                                {car.imageUrls.length > 1 && (
                                  <Typography sx={styles.imageCount}>
                                    +{car.imageUrls.length - 1}
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <Box style={styles.noImage}>
                                <Typography>
                                  No hay imágenes disponibles
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          <Button
                            variant="contained"
                            onClick={() => handleVerClick(car.id)}
                            fullWidth
                            sx={{ mt: 1, mb: 1 }}
                          >
                            Volver a contactar
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No has comprado ningún vehículo aún.</Typography>
              )}
            </>
          )}
        </Box>
      </Container>
      <BottomNav />
    </Container>
  );
};

export default VehiculosComponent;
