import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import jsQR from 'jsqr';

const QRScanner = ({ open, onClose }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let stream = null;
    let animationFrame = null;

    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        });
    
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play();
    
          const scanQRCode = () => {
            if (videoRef.current?.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
              const canvas = canvasRef.current;
              const video = videoRef.current;
              
              canvas.height = video.videoHeight;
              canvas.width = video.videoWidth;
              
              const context = canvas.getContext('2d');
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              
              const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
              const code = jsQR(imageData.data, imageData.width, imageData.height);
    
              if (code) {
                try {
                  const qrData = JSON.parse(code.data);
                  if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                  }
                  onClose();
                  navigate('/qr-payment', { 
                    state: { 
                      couponData: {
                        ...qrData,
                        scannedAt: new Date().toISOString()
                      }
                    } 
                  });
                } catch (err) {
                  setError('QR inválido');
                }
              }
            }
            animationFrame = requestAnimationFrame(scanQRCode);
          };
    
          scanQRCode();
        }
      } catch (err) {
        setError('No se pudo acceder a la cámara');
      }
    };

    if (open) {
      startCamera();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.srcObject = null;
      }
    };
  }, [open, navigate, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Escanear QR</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden'
            }}
          >
            <video
              ref={videoRef}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              autoPlay
              playsInline
              muted
            />
            <canvas
              ref={canvasRef}
              style={{ display: 'none' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '200px',
                height: '200px',
                border: '2px solid #ffffff',
                borderRadius: '8px'
              }}
            />
          </Box>
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QRScanner;