import React, { useEffect, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Grid,
  Box,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Alert,
  FormHelperText,
} from "@mui/material";
import {
  collection,
  onSnapshot,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db, getToken, uploadImage } from "../../firebase";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { isLoggedIn } from "../../session";
import Atras from "../atras";
import BottomNav from "./BottomNav";

const CAR_FORM_VALIDATION = {
  title: {
    required: true,
    minLength: 5,
    maxLength: 100,
    validate: (value) => {
      if (!value) return "El título es obligatorio";
      if (value.length < 5) return "El título debe tener al menos 5 caracteres";
      if (value.length > 100) return "El título no puede exceder 100 caracteres";
      return "";
    },
  },
  description: {
    required: true,
    minLength: 20,
    maxLength: 1000,
    validate: (value) => {
      if (!value) return "La descripción es obligatoria";
      if (value.length < 20) return "La descripción debe tener al menos 20 caracteres";
      if (value.length > 1000) return "La descripción no puede exceder 1000 caracteres";
      return "";
    },
  },
  price: {
    required: true,
    validate: (value) => {
      if (!value) return "El precio es obligatorio";
      if (isNaN(value) || value <= 0) return "El precio debe ser un número válido mayor que 0";
      return "";
    },
  },
  city: {
    required: true,
    validate: (value) => {
      if (!value) return "La ciudad es obligatoria";
      if (value.length < 2) return "El nombre de la ciudad es demasiado corto";
      return "";
    },
  },
  marca: {
    required: true,
    validate: (value) => {
      if (!value) return "La marca es obligatoria";
      return "";
    },
  },
  modelo: {
    required: true,
    validate: (value) => {
      if (!value) return "El modelo es obligatorio";
      if (value.length < 2) return "El modelo debe tener al menos 2 caracteres";
      return "";
    },
  },
  año: {
    required: true,
    validate: (value) => {
      if (!value) return "El año es obligatorio";
      const year = parseInt(value);
      const currentYear = new Date().getFullYear();
      if (isNaN(year) || year < 1900 || year > currentYear + 1) 
        return `El año debe estar entre 1900 y ${currentYear + 1}`;
      return "";
    },
  },
  km: {
    required: true,
    validate: (value) => {
      if (!value) return "El kilometraje es obligatorio";
      if (isNaN(value) || value < 0) return "El kilometraje debe ser un número válido";
      return "";
    },
  },
  condition: {
    required: true,
    validate: (value) => {
      if (!value) return "La condición es obligatoria";
      const validConditions = ["Nuevo", "Usado", "Reparar"];
      if (!validConditions.includes(value)) return "Seleccione una condición válida";
      return "";
    },
  },
  images: {
    required: true,
    validate: (value) => {
      if (!Array.isArray(value) || value.length === 0) return "Debe mantener al menos una imagen";
      if (value.length > 10) return "No puede tener más de 10 imágenes";
      return "";
    },
  },
};

const Marcas = [
  "Alfa Romeo",
  "Audi",
  "BMW",
  "Chevrolet",
  "Dodge",
  "Ferrari",
  "Fiat",
  "Ford",
  "Honda",
  "Hyundai",
  "Jeep",
  "Mercedez Benz",
  "Reanult",
  "Volkswagen",
];

const MyCars = () => {
  const [car, setCar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const validateField = (name, value) => {
    const fieldValidation = CAR_FORM_VALIDATION[name];
    if (!fieldValidation) return "";
    return fieldValidation.validate(value);
  };

  // Validar todo el formulario
  const validateForm = (car) => {
    const errors = {};
    let isValid = true;

    Object.keys(CAR_FORM_VALIDATION).forEach((fieldName) => {
      const error = validateField(fieldName, car[fieldName]);
      if (error) {
        errors[fieldName] = error;
        isValid = false;
      }
    });

    return { errors, isValid };
  };

  const handleEditClick = (car) => {
    setSelectedCar(car);
    setFormErrors({});
    setSubmitAttempted(false);
    setOpenModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCar(prev => ({ ...prev, [name]: value }));

    if (submitAttempted) {
      const error = validateField(name, value);
      setFormErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const handleSaveEdit = async () => {
    if (!selectedCar) return;

    setSubmitAttempted(true);
    const { errors, isValid } = validateForm(selectedCar);

    if (!isValid) {
      setFormErrors(errors);
      return;
    }

    try {
      const propertyRef = doc(db, "cars", selectedCar.id);
      const docSnap = await getDoc(propertyRef);

      if (!docSnap.exists()) {
        throw new Error("No se encontró el documento del vehículo.");
      }

      await updateDoc(propertyRef, {
        title: selectedCar.title,
        description: selectedCar.description,
        price: selectedCar.price,
        city: selectedCar.city,
        marca: selectedCar.marca,
        km: selectedCar.km,
        año: selectedCar.año,
        modelo: selectedCar.modelo,
        condition: selectedCar.condition,
        images: selectedCar.images,
      });
      
      handleCloseModal();
    } catch (error) {
      setFormErrors(prev => ({
        ...prev,
        submit: "Error al actualizar el vehículo: " + error.message
      }));
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    
    if (!files.length) return;

    if (selectedCar.images.length + files.length > 10) {
      setFormErrors(prev => ({
        ...prev,
        images: "No puede subir más de 10 imágenes"
      }));
      return;
    }

    setLoading(true);

    try {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      const updatedImages = [...selectedCar.images, ...imageUrls];
      setSelectedCar(prev => ({
        ...prev,
        images: updatedImages
      }));

      const error = validateField('images', updatedImages);
      setFormErrors(prev => ({
        ...prev,
        images: error
      }));

    } catch (error) {
      setFormErrors(prev => ({
        ...prev,
        images: "Error al subir las imágenes"
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedCar.images.filter((_, i) => i !== index);
    setSelectedCar(prev => ({
      ...prev,
      images: updatedImages
    }));

    if (submitAttempted) {
      const error = validateField('images', updatedImages);
      setFormErrors(prev => ({
        ...prev,
        images: error
      }));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMarcaChange = (e) => {
    setSelectedMarca(e.target.value);
  };

  const handleDelete = async (carId) => {
    try {
      const carRef = doc(db, "cars", carId);
      await deleteDoc(carRef);
    } catch (error) {
      console.error("Error al eliminar la propiedad:", error);
    }
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCar(null);
  };

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  useEffect(() => {
    const checkAuthAndFetchCars = async () => {
      if (!isLoggedIn()) {
        // Redirect to login page
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }

        setLoading(true);
        const { uid } = currentUser;
        const q = query(collection(db, "cars"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const carsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCar(carsData);
          setLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener vehiculos:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchCars();
  }, []);


  useEffect(() => {
    const checkAuthAndFetchCars = async () => {
      if (!isLoggedIn()) {
        // Redirect to login page
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }

        setLoading(true);
        const { uid } = currentUser;
        const q = query(collection(db, "cars"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const carsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCar(carsData);
          setLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener vehiculos:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchCars();
  }, []);



  const filteredCars = car.filter((car) => {
    const searchMatch =
      car.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      car.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      car.price.toString().includes(searchTerm) ||
      car.city.toString().includes(searchTerm) ||
      car.modelo.toString().includes(searchTerm) ||
      car.año.toString().includes(searchTerm) ||
      car.km.toString().includes(searchTerm) ||
      car.marca.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      (car.condition &&
        car.condition.toLowerCase().includes(searchTerm.toLowerCase())); // Add condition to search

    const brandMatch =
      (typeof selectedMarca === "string" && selectedMarca === "") ||
      (typeof selectedMarca === "string" &&
        car.marca.toLowerCase().includes(selectedMarca.toLowerCase()));

    return searchMatch && brandMatch;
  });

  const styles = {
    appBar: {
      bgcolor: "black",
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "auto",
      justifyContent: "space-around",
      alignItems: "center",
    },
    searchContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginTop: 2,
      color: "#fff",
    },
    searchField: {
      width: "70%",
      color: "black", // This sets the label color
    },
    continentField: {
      width: "28%",
      ml: 1,
      color: "black",
    },
    container: {
      mt: 25,
      mb: 20,
    },
    card: {
      border: "2px solid grey",
      borderRadius: "10px",
      padding: "20px",
    },
    contentContainer: {
      padding: "16px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    thumbnailContainer: {
      display: "flex",
      overflowX: "auto",
      marginTop: "10px",
    },
    thumbnail: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      marginRight: "10px",
      borderRadius: "5px",
    },
    imageContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "10px",
    },
    image: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      marginRight: "10px",
      marginBottom: "10px",
      borderRadius: "5px",
      position: "relative",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    deleteButton: {
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "5px",
      borderRadius: "50%",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "white" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            height: "auto",
          }}
        >
          <Atras />
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, ml: 3 }}
              color="black"
            >
              Editar vehículos
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar sx={{ display: "block", marginBottom: "20px" }}>
          <Box sx={styles.searchContainer}>
            <TextField
              label="Buscar vehículo..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              sx={styles.searchField}
            />
            <TextField
              select
              label="Marca"
              value={selectedMarca}
              onChange={handleMarcaChange}
              variant="outlined"
              sx={styles.continentField}
            >
              <MenuItem value="">Todos</MenuItem>
              {Marcas.map((marca) => (
                <MenuItem key={marca} value={marca}>
                  {marca}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Toolbar>
      </AppBar>

      <Container sx={styles.container}>
        {loading ? (
          <Box sx={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mb: 20 }}>
            {filteredCars.map((car) => (
              <Grid item key={car.id} xs={12} sm={6} md={4} lg={4}>
                <Box sx={styles.card}>
                  <Typography sx={styles.title}>{car.title}</Typography>
                  <Box sx={styles.contentContainer}>
                    <Typography
                      sx={{
                        ...styles.modelo,
                        color: "primary.main",
                        fontWeight: "bold",
                      }}
                    >
                      Condición:{" "}
                      {car.condition === "Nuevo"
                        ? "Nuevo"
                        : car.condition === "Usado"
                        ? "Usado"
                        : car.condition === "Reparar"
                        ? "Para reparar"
                        : "No especificado"}
                    </Typography>
                    <Typography sx={styles.description}>
                      {car.description}
                    </Typography>
                    <Typography sx={styles.price}>
                      Precio: $ {car.price}
                    </Typography>
                    <Typography sx={styles.modelo}>
                      Ciudad: {car.city}
                    </Typography>
                    <Typography sx={styles.modelo}>
                      Modelo: {car.modelo}
                    </Typography>
                    <Typography sx={styles.modelo}>Año: {car.año}</Typography>
                    <Typography sx={styles.modelo}>
                      Kilometros: {car.km} km
                    </Typography>
                    <Typography sx={styles.marca}>
                      Marca: {car.marca}
                    </Typography>
                    <Typography sx={styles.created}>
                      Fecha de publicación: {formatDate(car.createdAt)}
                    </Typography>
                    <Box sx={styles.thumbnailContainer}>
                      {car.images ? (
                        Array.isArray(car.images) ? (
                          car.images.map((image, index) => (
                            <CardMedia
                              key={index}
                              component="img"
                              image={image}
                              alt={`Imagen de ${car.title}`}
                              sx={styles.thumbnail}
                            />
                          ))
                        ) : (
                          <CardMedia
                            key={0}
                            component="img"
                            image={car.images}
                            alt={`Imagen de ${car.title}`}
                            sx={styles.thumbnail}
                          />
                        )
                      ) : (
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f0f0f0",
                            color: "#666",
                          }}
                        >
                          <Typography>No hay imágenes</Typography>
                        </Box>
                      )}
                    </Box>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => handleEditClick(car)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => handleDelete(car.id)}
                    >
                      Eliminar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      <Dialog 
        open={openModal} 
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Editar Vehículo</DialogTitle>
        <DialogContent>
          {formErrors.submit && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {formErrors.submit}
            </Alert>
          )}

          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Título"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCar?.title || ""}
            onChange={handleInputChange}
            error={!!formErrors.title}
            helperText={formErrors.title}
            required
          />

          <TextField
            margin="dense"
            name="description"
            label="Descripción"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={selectedCar?.description || ""}
            onChange={handleInputChange}
            error={!!formErrors.description}
            helperText={formErrors.description}
            required
          />

          <TextField
            margin="dense"
            name="price"
            label="Precio"
            type="number"
            fullWidth
            variant="outlined"
            value={selectedCar?.price || ""}
            onChange={handleInputChange}
            error={!!formErrors.price}
            helperText={formErrors.price}
            required
          />

          <TextField
            margin="dense"
            name="city"
            label="Ciudad"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCar?.city || ""}
            onChange={handleInputChange}
            error={!!formErrors.city}
            helperText={formErrors.city}
            required
          />

          <TextField
            margin="dense"
            name="modelo"
            label="Modelo"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCar?.modelo || ""}
            onChange={handleInputChange}
            error={!!formErrors.modelo}
            helperText={formErrors.modelo}
            required
          />

          <TextField
            margin="dense"
            name="año"
            label="Año"
            type="number"
            fullWidth
            variant="outlined"
            value={selectedCar?.año || ""}
            onChange={handleInputChange}
            error={!!formErrors.año}
            helperText={formErrors.año}
            required
          />

          <TextField
            margin="dense"
            name="km"
            label="Kilometraje"
            type="number"
            fullWidth
            variant="outlined"
            value={selectedCar?.km || ""}
            onChange={handleInputChange}
            error={!!formErrors.km}
            helperText={formErrors.km}
            required
          />

          <Typography sx={{ mt: 2 }}>Marca:</Typography>
          <Select
            name="marca"
            value={selectedCar?.marca || ""}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.marca}
            required
          >
            <MenuItem value="" disabled>Selecciona una marca</MenuItem>
            {Marcas.map(marca => (
              <MenuItem key={marca} value={marca}>{marca}</MenuItem>
            ))}
          </Select>
          {formErrors.marca && (
            <FormHelperText error>{formErrors.marca}</FormHelperText>
          )}

          <Typography sx={{ mt: 2 }}>Condición del vehículo:</Typography>
          <Select
            name="condition"
            value={selectedCar?.condition || ""}
            onChange={handleInputChange}
            fullWidth
            error={!!formErrors.condition}
            required
          >
            <MenuItem value="" disabled>Selecciona la condición</MenuItem>
            <MenuItem value="Nuevo">Nuevo</MenuItem>
            <MenuItem value="Usado">Usado</MenuItem>
            <MenuItem value="Reparar">Para reparar</MenuItem>
          </Select>
          {formErrors.condition && (
            <FormHelperText error>{formErrors.condition}</FormHelperText>
          )}

          {/* Sección de imágenes */}
          {(formErrors?.images || selectedCar?.images?.length === 0) && (
            <Alert 
              severity={formErrors?.images ? "error" : "warning"}
              sx={{ mt: 2, mb: 1 }}
            >
              {formErrors?.images || "Debe mantener al menos una imagen del vehículo"}
            </Alert>
          )}

          <label htmlFor="upload-button" style={{ width: "100%" }}>
            <Button
              variant="contained"
              component="div"
              sx={{
                ...styles.uploadButton,
                border: formErrors?.images ? "2px solid #d32f2f" : "2px solid #312783",
                mt: 2
              }}
              disabled={loading || (selectedCar?.images?.length >= 10)}
            >
              <AddPhotoAlternateIcon sx={{ color: "black" }} />
              {selectedCar?.images?.length >= 10 
                ? "Límite de imágenes alcanzado"
                : `Subir imagen${selectedCar?.images?.length > 0 ? ` (${selectedCar.images.length}/10)` : 'es'}`}
              {loading && <CircularProgress size={24} sx={{ ml: 1 }} />}
            </Button>
          </label>

          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="upload-button"
            multiple
          />

          {selectedCar?.images && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {selectedCar.images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "75%",
                    border: formErrors?.images ? "2px solid #d32f2f" : "2px solid #ccc",
                    borderRadius: "8px",
                    overflow: "hidden"
                  }}>
                    <img
                      src={imageUrl}
                      alt={`Imagen ${index + 1}`}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                      }}
                    />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        minWidth: "36px",
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        p: 0
                      }}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button 
            onClick={handleSaveEdit}
            variant="contained"
            color="primary"
            disabled={loading || Object.keys(formErrors).length > 0}
          >
            {loading ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <BottomNav />
    </Container>
  );
};

// Función de utilidad para formatear la fecha


export default MyCars;
