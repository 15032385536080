import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { isLoggedIn } from "../session";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  where,
  setDoc,
  updateDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CircularProgress,
  Avatar,
  Chip,
  AppBar,
  IconButton,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  Toolbar
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BottomNav from "./BottomToolbar";
import SendIcon from "@mui/icons-material/Send";


function ChatBox() {
  const { type, itemId, otherUserId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [conversationId, setConversationId] = useState(null);
  const [reservationPercentage, setReservationPercentage] = useState(30); // Default 30%
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchAuthUser = async () => {
      const user = await isLoggedIn();
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setCurrentUser({
            ...user,
            displayName: userDoc.data().displayName,
            profileImageUrl: userDoc.data().profileImageUrl,
          });
        } else {
          console.error("User document not found");
        }
      } else {
        navigate("/login");
      }
    };
    fetchAuthUser();
  }, [navigate]);

  const handleCheckoutRedirect = () => {
    navigate(`/checkout/${type}/${itemId}?percentage=${reservationPercentage}`);
  };

  const renderPaymentOptions = () => {
    const tooltipText =
      type === "auto"
        ? "Selecciona el porcentaje de pago inicial del vehículo"
        : "Selecciona el porcentaje de reserva para tu viaje";

    return (
      <Tooltip title={tooltipText}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="percentage-select-label">
            {type === "auto"
              ? "Porcentaje de Pago Inicial"
              : "Porcentaje de Reserva"}
          </InputLabel>
          <Select
            labelId="percentage-select-label"
            value={reservationPercentage}
            label={
              type === "auto"
                ? "Porcentaje de Pago Inicial"
                : "Porcentaje de Reserva"
            }
            onChange={(e) => setReservationPercentage(e.target.value)}
          >
            <MenuItem value={30}>30% - Pago Mínimo</MenuItem>
            <MenuItem value={50}>50% - Pago Medio</MenuItem>
            <MenuItem value={100}>100% - Pago Completo</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!conversationId || !currentUser) return;

    const messagesRef = collection(
      db,
      "conversations",
      conversationId,
      "messages"
    );
    const q = query(messagesRef, orderBy("timestamp"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(newMessages);
      scrollToBottom();
      markMessagesAsRead(newMessages);
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, currentUser]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || !currentUser) return;

    try {
      const messageData = {
        content: newMessage.trim(),
        timestamp: serverTimestamp(),
        senderId: currentUser.uid,
        senderDisplayName: currentUser.displayName,
        senderProfileImageUrl: currentUser.profileImageUrl || null,
        read: false,
      };

      const messagesRef = collection(
        db,
        `conversations/${conversationId}/messages`
      );
      await addDoc(messagesRef, messageData);

      const conversationRef = doc(db, "conversations", conversationId);
      await updateDoc(conversationRef, {
        lastMessage: messageData,
        [`unreadCount.${otherUserId}`]:
          (messages.filter((m) => !m.read && m.senderId !== currentUser.uid)
            .length || 0) + 1,
      });

      setNewMessage("");
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const getItemCollectionName = useCallback((itemType) => {
    switch (itemType) {
      case "auto":
        return "cars";
      case "viaje":
        return "places";
      default:
        console.error("Tipo de ítem desconocido:", itemType);
        return null;
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      if (!currentUser) {
        console.log("Current user not available yet");
        return;
      }

      if (!type || !itemId) {
        console.error("Missing type or itemId");
        return;
      }

      const itemCollectionName = getItemCollectionName(type);
      if (!itemCollectionName) {
        console.error("No se pudo determinar la colección del ítem");
        return;
      }

      const itemRef = doc(db, itemCollectionName, itemId);
      const itemDoc = await getDoc(itemRef);
      if (!itemDoc.exists()) {
        console.error("Item not found");
        return;
      }
      const itemData = itemDoc.data();
      setItemDetails(itemData);

      const conversationsRef = collection(db, "conversations");
      let q = query(conversationsRef);

      // Add conditions only if the values are defined
      if (type) {
        q = query(q, where("type", "==", type));
      }
      if (itemId) {
        q = query(q, where("itemId", "==", itemId));
      }
      if (currentUser.uid) {
        q = query(q, where("participants", "array-contains", currentUser.uid));
      }

      const querySnapshot = await getDocs(q);

      let conversationDoc;
      if (querySnapshot.empty) {
        const newConversationId = [currentUser.uid, otherUserId, type, itemId]
          .sort()
          .join("_");
        const newConversationRef = doc(conversationsRef, newConversationId);
        await setDoc(newConversationRef, {
          participants: [currentUser.uid, otherUserId],
          type,
          itemId,
          itemOwnerId: itemData.uid,
          lastMessage: null,
          unreadCount: { [currentUser.uid]: 0, [otherUserId]: 0 },
        });
        conversationDoc = await getDoc(newConversationRef);
      } else {
        conversationDoc = querySnapshot.docs[0];
      }

      setConversationId(conversationDoc.id);

      const otherUserRef = doc(db, "users", otherUserId);
      const otherUserDoc = await getDoc(otherUserRef);
      if (otherUserDoc.exists()) {
        const otherUserData = otherUserDoc.data();
        setOtherUser({
          uid: otherUserId,
          displayName: otherUserData.displayName || "Usuario",
          profileImageUrl: otherUserData.profileImageUrl || null,
        });
      } else {
        console.error("Other user not found in 'users' collection");
        setOtherUser({
          uid: otherUserId,
          displayName: "Usuario desconocido",
          profileImageUrl: null,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [type, itemId, otherUserId, currentUser, getItemCollectionName]);

  useEffect(() => {
    fetchData();
  }, [currentUser, fetchData]);

  useEffect(() => {
    if (!conversationId || !currentUser) return;

    const messagesRef = collection(
      db,
      "conversations",
      conversationId,
      "messages"
    );
    const q = query(messagesRef, orderBy("timestamp"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(newMessages);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

      markMessagesAsRead(newMessages);
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, currentUser]);

  const markMessagesAsRead = async (messages) => {
    if (!currentUser || !conversationId) return;

    const batch = writeBatch(db);
    let unreadCount = 0;

    messages.forEach((message) => {
      if (message.senderId !== currentUser.uid && !message.read) {
        const messageRef = doc(
          db,
          `conversations/${conversationId}/messages`,
          message.id
        );
        batch.update(messageRef, { read: true });
        unreadCount++;
      }
    });

    if (unreadCount > 0) {
      const conversationRef = doc(db, "conversations", conversationId);
      batch.update(conversationRef, {
        [`unreadCount.${currentUser.uid}`]: 0,
      });
    }

    try {
      await batch.commit();
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const renderItemDetails = () => {
    if (!itemDetails) return null;

    switch (type) {
      case "auto":
        return (
          <Box>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              Detalles del Auto:
            </Typography>
            <Link
              href={`/autos/ver/${itemId}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{ color: "lightblue", textDecoration: "underline" }}
              >
                Ver detalles del auto
              </Typography>
            </Link>
            <Typography sx={{ color: "white" }}>
              Marca: {itemDetails.marca}
            </Typography>
            <Typography sx={{ color: "white" }}>
              Modelo: {itemDetails.modelo}
            </Typography>
            <Typography sx={{ color: "white" }}>
              Precio: ${itemDetails.price}
            </Typography>
          </Box>
        );
      case "viaje":
        return (
          <Box>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              Detalles del Viaje:
            </Typography>
            <Link
              href={`/viajes/ver/${itemId}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{ color: "lightblue", textDecoration: "underline" }}
              >
                Ver detalles del viaje
              </Typography>
            </Link>
            <Typography sx={{ color: "white" }}>
              Destino: {itemDetails.city}
            </Typography>
            <Typography sx={{ color: "white" }}>
              Lugar: {itemDetails.place}
            </Typography>
            <Typography sx={{ color: "white" }}>
              Precio por día: ${itemDetails.price}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const formatTime = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate();
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "";
  };

  const renderMessage = (message) => {
    const isCurrentUser = message.senderId === currentUser?.uid;
    const userToDisplay = isCurrentUser ? currentUser : otherUser;

    return (
      <Box
        key={message.id}
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: isCurrentUser ? "flex-end" : "flex-start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", maxWidth: "70%" }}>
          {!isCurrentUser && (
            <Avatar
              src={userToDisplay?.profileImageUrl}
              alt={userToDisplay?.displayName}
              sx={{ width: 24, height: 24, mr: 1, mb: 1 }}
            />
          )}
          <Box
            sx={{
              bgcolor: isCurrentUser ? "#CAEDED" : "#FFF8E1",
              p: 1,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
                color: isCurrentUser ? "#2196F3" : "#4CAF50",
                mb: 0.5,
              }}
            >
              {userToDisplay?.displayName}
            </Typography>
            <Typography variant="body1">{message.content}</Typography>
          </Box>
          {isCurrentUser && (
            <Avatar
              src={userToDisplay.profileImageUrl}
              alt={userToDisplay.displayName}
              sx={{ width: 24, height: 24, ml: 1, mb: 1 }}
            />
          )}
        </Box>
        <Typography variant="caption" sx={{ mt: 0.5, color: "white" }}>
          {formatTime(message.timestamp)} - {message.read ? "Leído" : "Enviado"}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "slateblue",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Chat con {otherUser?.displayName} - {itemDetails?.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          mt: 10,
          mb: 8,
        }}
      >
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                src={otherUser?.profileImageUrl}
                alt={otherUser?.displayName}
              />
              <Typography variant="h6" color={"white"} sx={{ ml: 1 }}>
                Chat con {otherUser?.displayName} - {itemDetails?.title}
              </Typography>
              <Chip label={type.toUpperCase()} color="primary" sx={{ ml: 2 }} />
            </Box>
            {renderItemDetails()}
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                bgcolor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 2,
                p: 2,
                mb: 2,
              }}
            >
              {messages.map(renderMessage)}
              <div ref={messagesEndRef} />
            </Box>
            <Box sx={{ bgcolor: "background.paper", p: 2 }}>
              <Box sx={{ display: "flex", mb: 2 }}>
                <TextField
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  placeholder="Escribe tu mensaje..."
                  variant="outlined"
                  fullWidth
                  multiline
                  maxRows={4}
                  sx={{ mr: 1 }}
                />
                <IconButton color="primary" onClick={handleSendMessage}>
                  <SendIcon />
                </IconButton>
              </Box>
              {renderPaymentOptions()}
              <Button
                variant="contained"
                onClick={handleCheckoutRedirect}
                fullWidth
                sx={{
                  bgcolor: "secondary.main",
                  color: "white",
                  py: 1.5,
                  fontSize: "1.1rem",
                }}
              >
                {type === "auto"
                  ? `Pagar ${reservationPercentage}% del vehículo`
                  : `Pagar ${reservationPercentage}% de reserva`}
              </Button>
            </Box>
            <BottomNav />
          </>
        )}
      </Container>
    </Box>
  );
}

export default ChatBox;
