import React, { useEffect, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Grid,
  Box,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Alert
} from "@mui/material";
import {
  collection,
  onSnapshot,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, db, getToken, uploadImage } from "../../firebase";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { isLoggedIn } from "../../session";
import Atras from "../atras";
import BottomNav from "./BottomNav";

const FORM_VALIDATION = {
  title: {
    required: true,
    minLength: 5,
    maxLength: 100,
    validate: (value) => {
      if (!value) return "El título es obligatorio";
      if (value.length < 5) return "El título debe tener al menos 5 caracteres";
      if (value.length > 100) return "El título no puede exceder 100 caracteres";
      return "";
    },
  },
  description: {
    required: true,
    minLength: 20,
    maxLength: 1000,
    validate: (value) => {
      if (!value) return "La descripción es obligatoria";
      if (value.length < 20) return "La descripción debe tener al menos 20 caracteres";
      if (value.length > 1000) return "La descripción no puede exceder 1000 caracteres";
      return "";
    },
  },
  price: {
    required: true,
    validate: (value) => {
      if (!value) return "El precio es obligatorio";
      if (isNaN(value) || value <= 0) return "El precio debe ser un número válido mayor que 0";
      return "";
    },
  },
  place: {
    required: true,
    validate: (value) => {
      if (!value) return "El lugar es obligatorio";
      return "";
    },
  },
  category: {
    required: true,
    validate: (value) => {
      if (!value) return "La categoría es obligatoria";
      return "";
    },
  },
  city: {
    required: true,
    validate: (value) => {
      if (!value) return "La ciudad es obligatoria";
      if (value.length < 2) return "El nombre de la ciudad es demasiado corto";
      return "";
    },
  },
  images: {
    required: true,
    validate: (value) => {
      if (!Array.isArray(value) || value.length === 0) return "Debe mantener al menos una imagen";
      if (value.length > 10) return "No puede tener más de 10 imágenes";
      return "";
    },
  },
};

const CONTINENTS = [
  "Asia",
  "América del Norte",
  "América del Sur",
  "Europa",
  "Medio Oriente",
];

const MyProperties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  // Validar un campo específico
  const validateField = (name, value) => {
    const fieldValidation = FORM_VALIDATION[name];
    if (!fieldValidation) return "";
    return fieldValidation.validate(value);
  };

  // Validar todos los campos
  const validateForm = (property) => {
    if (!property) return { errors: {}, isValid: false };
    
    const errors = {};
    let isValid = true;
  
    Object.keys(FORM_VALIDATION).forEach((fieldName) => {
      const error = validateField(fieldName, property[fieldName]);
      if (error) {
        errors[fieldName] = error;
        isValid = false;
      }
    });
  
    return { errors, isValid };
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleContinentChange = (e) => {
    setSelectedContinent(e.target.value);
  };

  const handleDelete = async (propertyId) => {
    try {
      const propertyRef = doc(db, "places", propertyId);
      await deleteDoc(propertyRef);
    } catch (error) {
      console.error("Error al eliminar la propiedad:", error);
    }
  };

  const handleEditClick = (property) => {
    setSelectedProperty(property);
    setFormErrors({});
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProperty(null);
    setFormErrors({});
  };

  const handleSaveEdit = async () => {
    if (!selectedProperty) return;
  
    // Validar todos los campos antes de guardar
    const { errors, isValid } = validateForm(selectedProperty);
    
    if (!isValid) {
      setFormErrors(errors);
      return;
    }
  
    try {
      const propertyRef = doc(db, "places", selectedProperty.id);
      await updateDoc(propertyRef, {
        title: selectedProperty.title,
        description: selectedProperty.description,
        price: selectedProperty.price,
        city: selectedProperty.city,
        place: selectedProperty.place,
        category: selectedProperty.category,
        images: selectedProperty.images,
      });
      handleCloseModal();
    } catch (error) {
      console.error("Error al actualizar la propiedad:", error);
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    
    if (!files.length) return;

    // Validar límite de imágenes
    if (selectedProperty.images.length + files.length > 10) {
      setFormErrors(prev => ({
        ...prev,
        images: "No puede subir más de 10 imágenes"
      }));
      return;
    }

    setLoading(true);

    try {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      const updatedImages = [...selectedProperty.images, ...imageUrls];
      setSelectedProperty(prev => ({
        ...prev,
        images: updatedImages
      }));

      // Validar imágenes después de la actualización
      const error = validateField('images', updatedImages);
      setFormErrors(prev => ({
        ...prev,
        images: error
      }));

    } catch (error) {
      setFormErrors(prev => ({
        ...prev,
        images: "Error al subir las imágenes"
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedProperty.images.filter((_, i) => i !== index);
    setSelectedProperty(prev => ({
      ...prev,
      images: updatedImages
    }));

    // Validar imágenes después de eliminar
    const error = validateField('images', updatedImages);
    setFormErrors(prev => ({
      ...prev,
      images: error
    }));
  };

const handleInputChange = (e) => {
  const { name, value } = e.target;
  const updatedProperty = {
    ...selectedProperty,
    [name]: value
  };
  
  setSelectedProperty(updatedProperty);
  
  // Validar el campo específico que cambió
  const error = validateField(name, value);
  setFormErrors(prev => ({
    ...prev,
    [name]: error
  }));

  // También validar todo el formulario para actualizar el estado del botón
  validateForm(updatedProperty);
};


  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  useEffect(() => {
    const checkAuthAndFetchProperties = async () => {
      if (!isLoggedIn()) {
        // Redirect to login page
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }

        setLoading(true);
        const { uid } = currentUser;
        const q = query(collection(db, "places"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const propertiesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProperties(propertiesData);
          setLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener propiedades:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchProperties();
  }, []);

  

  const filteredProperties = properties.filter((property) => {
    const searchMatch =
      property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.price.toString().includes(searchTerm) ||
      property.city.toString().includes(searchTerm) ||
      property.place.toLowerCase().includes(searchTerm.toLowerCase());

    const continentMatch =
      selectedContinent === "" ||
      property.place.toLowerCase().includes(selectedContinent.toLowerCase());

    return searchMatch && continentMatch;
  });



  const styles = {
    appBar: {
      bgcolor: "black",
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "auto",
      justifyContent: "space-around",
      alignItems: "center",
    },
    searchContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginTop: 2,
      color: "#fff",
    },
    searchField: {
      width: "70%",
      color: "black",
    },
    continentField: {
      width: "28%",
      ml: 1,
      color: "black",
    },
    container: {
      mt: 25,
      mb: 20,
    },
    card: {
      border: "2px solid grey",
      borderRadius: "10px",
      padding: "20px",
    },
    contentContainer: {
      padding: "16px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    thumbnailContainer: {
      display: "flex",
      overflowX: "auto",
      marginTop: "10px",
    },
    thumbnail: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      marginRight: "10px",
      borderRadius: "5px",
    },
    imageContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "10px",
    },
    image: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      marginRight: "10px",
      marginBottom: "10px",
      borderRadius: "5px",
      position: "relative",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    deleteButton: {
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "5px",
      borderRadius: "50%",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "white" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            height: "auto",
          }}
        >
          <Atras />
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, ml: 3 }}
              color="black"
            >
              Mis Propiedades
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar sx={{ display: "block", marginBottom: "20px" }}>
          <Box sx={styles.searchContainer}>
            <TextField
              label="Buscar propiedad..."
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              sx={styles.searchField}
            />
            <TextField
              select
              label="Continente"
              value={selectedContinent}
              onChange={handleContinentChange}
              variant="outlined"
              sx={styles.continentField}
            >
              <MenuItem value="">Todos</MenuItem>
              {CONTINENTS.map((continent) => (
                <MenuItem key={continent} value={continent}>
                  {continent}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Toolbar>
      </AppBar>

      <Container sx={styles.container}>
        {loading ? (
          <Box sx={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mb: 20 }}>
            {filteredProperties.map((property) => (
              <Grid item key={property.id} xs={12} sm={6} md={4} lg={4}>
                <Box sx={styles.card}>
                  <Typography sx={styles.title}>{property.title}</Typography>
                  <Box sx={styles.contentContainer}>
                    <Typography sx={styles.description}>
                      {property.description}
                    </Typography>
                    <Typography sx={styles.price}>
                      Precio: $ {property.price}
                    </Typography>
                    <Typography sx={styles.place}>
                      Ciudad: {property.city}
                    </Typography>
                    <Typography sx={styles.place}>
                      Lugar: {property.place}
                    </Typography>
                    <Typography sx={styles.place}>
                      Categoria: {property.category}
                    </Typography>
                    <Typography sx={styles.created}>
                      Fecha de publicación: {formatDate(property.createdAt)}
                    </Typography>
                    <Box sx={styles.thumbnailContainer}>
                      {property.images ? (
                        Array.isArray(property.images) ? (
                          property.images.map((image, index) => (
                            <CardMedia
                              key={index}
                              component="img"
                              image={image}
                              alt={`Imagen de ${property.title}`}
                              sx={styles.thumbnail}
                            />
                          ))
                        ) : (
                          <CardMedia
                            key={0}
                            component="img"
                            image={property.images}
                            alt={`Imagen de ${property.title}`}
                            sx={styles.thumbnail}
                          />
                        )
                      ) : (
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f0f0f0",
                            color: "#666",
                          }}
                        >
                          <Typography>No hay imágenes</Typography>
                        </Box>
                      )}
                    </Box>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => handleEditClick(property)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={() => handleDelete(property.id)}
                    >
                      Eliminar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      <Dialog 
        open={openModal} 
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Editar Propiedad</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Título"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedProperty?.title || ""}
            onChange={handleInputChange}
            error={!!formErrors.title}
            helperText={formErrors.title}
            required
          />
          <TextField
            margin="dense"
            name="description"
            label="Descripción"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={selectedProperty?.description || ""}
            onChange={handleInputChange}
            error={!!formErrors.description}
            helperText={formErrors.description}
            required
          />
          <TextField
            margin="dense"
            name="price"
            label="Precio"
            type="number"
            fullWidth
            variant="outlined"
            value={selectedProperty?.price || ""}
            onChange={handleInputChange}
            error={!!formErrors.price}
            helperText={formErrors.price}
            required
          />
          <TextField
            margin="dense"
            name="city"
            label="Ciudad"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedProperty?.city || ""}
            onChange={handleInputChange}
            error={!!formErrors.city}
            helperText={formErrors.city}
            required
          />
          <Typography sx={{ mt: 2 }}>Tipo de publicación:</Typography>
          <TextField
            select
            margin="dense"
            name="category"
            label="Categoría"
            fullWidth
            variant="outlined"
            value={selectedProperty?.category || ""}
            onChange={handleInputChange}
            error={!!formErrors.category}
            helperText={formErrors.category}
            required
          >
            <MenuItem value="alojamiento">Alojamiento</MenuItem>
            <MenuItem value="compra">Compra</MenuItem>
            <MenuItem value="alquiler">Alquiler</MenuItem>
          </TextField>
          
          <Typography sx={{ mt: 2 }}>Seleccione un lugar:</Typography>
          <Select
            name="place"
            value={selectedProperty?.place || ""}
            onChange={handleInputChange}
            displayEmpty
            fullWidth
            error={!!formErrors.place}
            required
          >
            <MenuItem value="" disabled>
              Selecciona un continente
            </MenuItem>
            {CONTINENTS.map((continent) => (
              <MenuItem key={continent} value={continent}>
                {continent}
              </MenuItem>
            ))}
          </Select>
          {formErrors.place && (
            <Typography color="error" variant="caption">
              {formErrors.place}
            </Typography>
          )}

          {/* Sección de imágenes */}
          {(formErrors?.images || 
            (selectedProperty?.images?.length === 0)) && (
            <Alert 
              severity={formErrors?.images ? "error" : "warning"}
              sx={{ mt: 2, mb: 1 }}
            >
              {formErrors?.images || "Debe mantener al menos una imagen de la propiedad"}
            </Alert>
          )}

          <label htmlFor="upload-button" style={{ width: "100%" }}>
            <Button
              variant="contained"
              component="div"
              sx={{
                ...styles.uploadButton,
                border: formErrors?.images ? "2px solid #d32f2f" : "2px solid #312783",
                mt: 2
              }}
              disabled={loading || (selectedProperty?.images?.length >= 10)}
            >
              <AddPhotoAlternateIcon sx={{ color: "black" }} />
              {selectedProperty?.images?.length >= 10 
                ? "Límite de imágenes alcanzado" 
                : `Subir Imagen${selectedProperty?.images?.length > 0 
                    ? ` (${selectedProperty.images.length}/10)` 
                    : 'es'}`}
              {loading && (
                <CircularProgress size={40} thickness={4} sx={styles.progress} />
              )}
            </Button>
          </label>

          <input
            type="file"
            accept="image/*"
            onClick={(event) => (event.target.value = null)}
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="upload-button"
            multiple
          />

          {selectedProperty?.images && (
            <Grid container sx={{ mt: 2 }}>
              {selectedProperty.images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      border: formErrors?.images 
                        ? "1.5px solid #d32f2f" 
                        : "1.5px solid black",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="Imagen de la propiedad"
                      style={styles.imagePreview}
                    />
                    <Button
                      variant="contained"
                      sx={styles.removeButton}
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button 
            onClick={handleSaveEdit}
            disabled={!selectedProperty || !validateForm(selectedProperty).isValid}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <BottomNav />
    </Container>
  );
};

export default MyProperties;
