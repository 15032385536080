import React from 'react';
import {
  Toolbar,
  IconButton,
  Divider
} from '@mui/material';
import OfferIcon from '@mui/icons-material/LocalOffer';
import EventIcon from '@mui/icons-material/Event';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlightIcon from '@mui/icons-material/Flight';


import { Link } from 'react-router-dom';

const CustomToolbar = () => {

  const styles = {
    toolbar: {
      justifyContent: 'space-around',
      backgroundColor: 'white',
      color: 'black',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    iconButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
    },
    menuItemIcon: {
      marginRight: '8px',
    },
    divider: {
      height: 34,
      margin: '14px 0',
    },
  };

  return (
    <Toolbar sx={styles.toolbar}>
      <IconButton component={Link} to="/home" color="inherit" sx={styles.iconButton}>
        <OfferIcon />
      </IconButton>

      <IconButton component={Link} to="/events" color="inherit" sx={styles.iconButton}>
        <EventIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem sx={styles.divider} />

      <IconButton component={Link} to="/cars" color="inherit" sx={styles.iconButton}>
      <DirectionsCarIcon />
      </IconButton>

      <IconButton component={Link} to="/viajes" color="inherit" sx={styles.iconButton}>
        <FlightIcon/>
      </IconButton>
    </Toolbar>
  );
};

export default CustomToolbar;