import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  Alert,
  CircularProgress,
} from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { auth, db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const PaymentQRGenerator = () => {
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [qrData, setQRData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const calculateTotal = useCallback(() => {
    const total = selectedOffers.reduce((sum, offer) => {
      const price = parseFloat(offer.price) || 0;
      const discount = parseFloat(offer.offer) || 0;
      return sum + (price - (price * discount / 100));
    }, 0);
    setTotalAmount(total);
  }, [selectedOffers]);

  useEffect(() => {
    const fetchOffers = async () => {
      if (!auth.currentUser) {
        setError("No se encontró un usuario autenticado");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const offersQuery = query(
          collection(db, "offers"),
          where("uid", "==", auth.currentUser.uid)
        );
        const offersSnap = await getDocs(offersQuery);
        
        setOffers(offersSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })));
      } catch (error) {
        console.error("Error fetching offers:", error);
        setError("Error al cargar las ofertas");
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const handleOfferToggle = useCallback((offer) => {
    setSelectedOffers(prev => {
      const currentIndex = prev.findIndex(p => p.id === offer.id);
      if (currentIndex === -1) {
        return [...prev, offer];
      }
      const newSelected = [...prev];
      newSelected.splice(currentIndex, 1);
      return newSelected;
    });
  }, []);

  const generateQR = useCallback(() => {
    if (selectedOffers.length === 0) {
      setError("Selecciona al menos una oferta");
      return;
    }

    const qrContent = {
      type: 'payment',
      merchantId: auth.currentUser.uid,
      storeName: auth.currentUser.displayName,
      offers: selectedOffers.map(offer => ({
        id: offer.id,
        title: offer.product,
        price: offer.price,
        offer: offer.offer,
        description: offer.description
      })),
      totalAmount,
      timestamp: new Date().toISOString()
    };

    setQRData(qrContent);
    setError(null);
  }, [selectedOffers, totalAmount]);

  const renderPrice = useCallback((offer) => {
    const price = parseFloat(offer.price) || 0;
    const discount = parseFloat(offer.offer) || 0;
    const finalPrice = price - (price * discount / 100);

    return (
      <Box sx={{ textAlign: 'right' }}>
        {discount > 0 && (
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ textDecoration: 'line-through' }}
          >
            ${price.toFixed(2)}
          </Typography>
        )}
        <Typography variant="h6" color="primary">
          ${finalPrice.toFixed(2)}
        </Typography>
        {discount > 0 && (
          <Typography variant="caption" color="success.main">
            {discount}% OFF
          </Typography>
        )}
      </Box>
    );
  }, []);

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Generar QR de Cobro
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm" sx={{ pt: 10, pb: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Selecciona las ofertas a cobrar
          </Typography>

          {loading ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          ) : offers.length === 0 ? (
            <Alert severity="info" sx={{ my: 2 }}>
              No tienes ofertas publicadas.
              <Button 
                color="primary"
                onClick={() => navigate('/publish-offer')}
                sx={{ ml: 1 }}
              >
                Crear oferta
              </Button>
            </Alert>
          ) : (
            <>
              <List>
                {offers.map((offer) => (
                  <ListItem 
                    key={offer.id}
                    divider
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Checkbox
                      checked={selectedOffers.some(p => p.id === offer.id)}
                      onChange={() => handleOfferToggle(offer)}
                    />
                    <ListItemText
                      primary={offer.product.length > 50
                        ? `${offer.product.substring(0, 50)}...`
                        : offer.product}
                      secondary={offer.description.length > 100
                        ? `${offer.description.substring(0, 100)}...`
                        : offer.description}
                    />
                    {renderPrice(offer)}
                  </ListItem>
                ))}
              </List>

              <Divider sx={{ my: 3 }} />

              <Typography variant="h5" align="center" gutterBottom>
                Total: ${totalAmount.toFixed(2)}
              </Typography>

              <Button
                variant="contained"
                fullWidth
                onClick={generateQR}
                disabled={selectedOffers.length === 0}
                sx={{
                  bgcolor: "#312783",
                  "&:hover": { bgcolor: "#251e62" },
                  mb: 3
                }}
              >
                Generar QR
              </Button>

              {qrData && (
                <Box sx={{ textAlign: 'center' }}>
                  <QRCodeCanvas
                    id="payment-qr"
                    value={JSON.stringify(qrData)}
                    size={256}
                    level="H"
                    includeMargin
                  />
                </Box>
              )}
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default PaymentQRGenerator;