import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FlightIcon from '@mui/icons-material/Flight';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';


const BottomToolbar = () => {
    const classes = {
        appBar: {
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            color: "black",
            borderTop: "1.5px solid black"
        },
        toolbar: {
            justifyContent: "space-around",
        },
    };
    return (
        <AppBar position="fixed" sx={classes.appBar}>
            <Toolbar sx={classes.toolbar}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/home" sx={{color:"black"}}>
                        <HomeIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Inicio
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/viajes" sx={{color:"black"}}>
                        <FlightIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Viajes/Inmobiliarios
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/trips" sx={{color:"black"}}>
                        <CheckIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Reservados
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/Properties" sx={{color:"black"}}>
                        <EditIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Editar
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default BottomToolbar;
