import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  TextField,
  Button,
  CircularProgress,
  Box,
  Alert,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import { parsePhoneNumber, AsYouType, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import Atras from "./atras";
import { updateUser, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import BottomToolbar from "./BottomToolbar";
import { useNavigate } from "react-router-dom";

// Base de datos de países utilizando la librería i18n-iso-countries
const countriesData = getCountries().map(countryCode => {
  try {
    return {
      code: countryCode,
      name: new Intl.DisplayNames(['es'], { type: 'region' }).of(countryCode),
      phoneCode: `+${getCountryCallingCode(countryCode)}`,
      flag: countryCode.toUpperCase().replace(/./g, char => 
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    };
  } catch (e) {
    return null;
  }
}).filter(Boolean).sort((a, b) => a.name.localeCompare(b.name));

const Edit = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    displayName: "",
    phone: "",
    point: ""
  });
  const [selectedCountry, setSelectedCountry] = useState(
    countriesData.find(country => country.code === 'AR') || countriesData[0]
  );
  const [errors, setErrors] = useState({
    displayName: "",
    phone: "",
    point: ""
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        try {
          if (user.phoneNumber) {
            try {
              const phoneNumber = parsePhoneNumber(user.phoneNumber);
              const country = countriesData.find(c => c.code === phoneNumber.country);
              if (country) {
                setSelectedCountry(country);
              }
            } catch (e) {
              console.error("Error parsing phone number:", e);
            }
          }

          setFormData({
            displayName: user.displayName || "",
            phone: user.phoneNumber || "",
            point: user.point || ""
          });
        } catch (error) {
          console.error("Error loading user data:", error);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const validateDisplayName = (name) => {
    if (!name.trim()) {
      return "El nombre es requerido";
    }
    if (name.length < 2) {
      return "El nombre debe tener al menos 2 caracteres";
    }
    return "";
  };

  const validatePhone = (phoneNumber, country) => {
    if (!phoneNumber.trim()) {
      return "El número de teléfono es requerido";
    }

    try {
      const fullNumber = phoneNumber.startsWith('+') ? 
        phoneNumber : 
        `${country.phoneCode}${phoneNumber.replace(/^\D+/g, '')}`;
      
      const parsedNumber = parsePhoneNumber(fullNumber, country.code);
      
      if (!parsedNumber.isValid()) {
        return `Número inválido para ${country.name}`;
      }
      
      return "";
    } catch (error) {
      return "Formato de número inválido";
    }
  };

  const validatePoint = (point) => {
    if (!point.trim()) {
      return "La localidad es requerida";
    }
    return "";
  };

  const formatPhoneNumber = (value, country) => {
    try {
      const formatter = new AsYouType(country.code);
      let formatted = formatter.input(value);
      
      // Si el número no comienza con el código del país, agregarlo
      if (!formatted.startsWith(country.phoneCode)) {
        formatted = formatted.replace(/^\D+/g, ''); // Eliminar caracteres no numéricos del inicio
        formatted = `${country.phoneCode} ${formatted}`;
      }
      
      return formatted;
    } catch (e) {
      return value;
    }
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    if (field === 'phone') {
      value = formatPhoneNumber(value, selectedCountry);
    }

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    if (field === 'phone') {
      const error = validatePhone(value, selectedCountry);
      setErrors(prev => ({
        ...prev,
        phone: error
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        [field]: ""
      }));
    }

    setHasChanges(true);
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
      // Reformatear el número existente con el nuevo código de país
      if (formData.phone) {
        const phoneWithoutCode = formData.phone.replace(/^\+\d+\s*/, '');
        const newPhone = formatPhoneNumber(phoneWithoutCode, newValue);
        setFormData(prev => ({
          ...prev,
          phone: newPhone
        }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {
      displayName: validateDisplayName(formData.displayName),
      phone: validatePhone(formData.phone, selectedCountry),
      point: validatePoint(formData.point)
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== "");
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Por favor, corrija los errores antes de guardar",
        severity: "error"
      });
      return;
    }

    setLoading(true);

    try {
      await updateUser(
        user.uid,
        formData.displayName,
        formData.phone,
        formData.point
      );

      setSnackbar({
        open: true,
        message: "Perfil actualizado con éxito",
        severity: "success"
      });

      setHasChanges(false);
      
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      console.error("Error updating profile:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar el perfil: " + error.message,
        severity: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (hasChanges) {
      if (window.confirm("¿Seguro que deseas salir? Los cambios no guardados se perderán.")) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
            <Atras color="inherit" />
          </Box>
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Editar perfil
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 16 }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            textAlign: "left",
            marginTop: "20px",
          }}
        >
          <TextField
            label="Nombre"
            variant="outlined"
            value={formData.displayName}
            onChange={handleChange('displayName')}
            error={!!errors.displayName}
            helperText={errors.displayName}
            fullWidth
            margin="normal"
          />

          <Autocomplete
            options={countriesData}
            value={selectedCountry}
            onChange={handleCountryChange}
            getOptionLabel={(option) => `${option.flag} ${option.name} (${option.phoneCode})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="País"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
            isOptionEqualToValue={(option, value) => option.code === value.code}
          />

          <TextField
            label="Teléfono"
            variant="outlined"
            value={formData.phone}
            onChange={handleChange('phone')}
            error={!!errors.phone}
            helperText={errors.phone || `Formato para ${selectedCountry.name}: ${selectedCountry.phoneCode}`}
            fullWidth
            margin="normal"
            InputProps={{
              placeholder: `${selectedCountry.phoneCode} XXXXXXXXX`,
            }}
          />

          <TextField
            label="Localidad"
            variant="outlined"
            value={formData.point}
            onChange={handleChange('point')}
            error={!!errors.point}
            helperText={errors.point}
            fullWidth
            margin="normal"
          />

          <Button
            sx={{ marginTop: 2 }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !hasChanges}
          >
            {loading ? <CircularProgress size={24} /> : "Guardar cambios"}
          </Button>
        </Container>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <BottomToolbar />
    </Container>
  );
};

export default Edit;