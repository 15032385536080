import React, { useState, useEffect } from 'react';
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DirectionsCar, TimeToLeave, Build } from '@mui/icons-material';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import logoSvg from '../../imgs/logo.svg';
import BottomNav from './BottomNav';
import Search from '../search';

const CAR_CONDITIONS = [
  {
    id: 'Nuevo',
    label: 'Nuevo',
    color: '#4a90e2',
    gradient: 'linear-gradient(135deg, #4a90e2 0%, #357abd 100%)',
    icon: DirectionsCar,
    description: 'Vehículos 0km directo de concesionaria'
  },
  {
    id: 'Usado',
    label: 'Usado',
    color: '#48bb78',
    gradient: 'linear-gradient(135deg, #48bb78 0%, #38a169 100%)',
    icon: TimeToLeave,
    description: 'Vehículos usados en excelente estado'
  },
  {
    id: 'Reparacion',
    label: 'Para reparar',
    color: '#ed8936',
    gradient: 'linear-gradient(135deg, #ed8936 0%, #dd6b20 100%)',
    icon: Build,
    description: 'Vehículos que necesitan reparación'
  }
];

const CAR_BRANDS = [
  { label: 'Alfa Romeo', logo: '🚗' },
  { label: 'Audi', logo: '🚗' },
  { label: 'BMW', logo: '🚗' },
  { label: 'Chevrolet', logo: '🚗' },
  { label: 'Dodge', logo: '🚗' },
  { label: 'Ferrari', logo: '🚗' },
  { label: 'Fiat', logo: '🚗' },
  { label: 'Ford', logo: '🚗' },
  { label: 'Honda', logo: '🚗' },
  { label: 'Hyundai', logo: '🚗' },
  { label: 'Jeep', logo: '🚗' },
  { label: 'Mercedes Benz', logo: '🚗' },
  { label: 'Renault', logo: '🚗' },
  { label: 'Volkswagen', logo: '🚗' }
];

const CarsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [screen, setScreen] = useState('carCondition');
  const [cars, setCars] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredCars, setFilteredCars] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCars = async () => {
      const carsCollectionRef = collection(db, 'cars');
      const unsubscribe = onSnapshot(
        query(carsCollectionRef, orderBy('createdAt', 'desc')),
        (snapshot) => {
          const carsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            imageUrls: Array.isArray(doc.data().images)
              ? doc.data().images
              : [doc.data().images].filter(Boolean)
          }));
          setCars(carsData);
        }
      );
      return () => unsubscribe();
    };
    fetchCars();
  }, []);

  const handleConditionSelect = (condition) => {
    setSelectedCondition(condition);
    setScreen('brands');
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand);
    setScreen('results');
  };

  useEffect(() => {
    if (!cars.length) return;

    const filtered = cars.filter(
      (car) =>
        (!selectedCondition || car.condition?.toLowerCase() === selectedCondition.toLowerCase()) &&
        (!selectedBrand || car.marca?.toLowerCase() === selectedBrand.toLowerCase())
    );
    setFilteredCars(filtered);
  }, [cars, selectedCondition, selectedBrand]);

  const styles = {
    header: {
      position: 'fixed',
      top: 0,
      width: '100%',
      bgcolor: 'white',
      zIndex: 1100
    },
    logo: {
      width: isMobile ? '120px' : '120px',
      marginTop: '15px',
      marginBottom: '15px'
    },
    conditionButton: {
      width: '100%',
      minHeight: isMobile ? '160px' : '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      padding: '20px',
      color: 'white',
      transition: 'all 0.3s ease',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 12px rgba(0,0,0,0.15)'
      }
    },
    brandButton: {
      width: '100%',
      height: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '12px',
      background: 'white',
      border: '2px solid #e2e8f0',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        borderColor: '#4a90e2'
      }
    },
    conditionContainer: {
      padding: isMobile ? '20px' : '40px',
      backgroundColor: '#f5f7fa',
      borderRadius: '24px',
      margin: '0 auto',
      maxWidth: '1200px'
    }
  };

  const renderConditionScreen = () => (
    <Box sx={styles.conditionContainer}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: 'bold', color: '#1a365d' }}>
        ¿Qué tipo de vehículo buscas?
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {CAR_CONDITIONS.map((condition) => {
          const IconComponent = condition.icon;
          return (
            <Grid item xs={12} sm={4} key={condition.id}>
              <Button
                onClick={() => handleConditionSelect(condition.id)}
                style={{
                  ...styles.conditionButton,
                  background: condition.gradient
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.1,
                    background: 'radial-gradient(circle at top right, white 0%, transparent 60%)'
                  }}
                />
                <IconComponent sx={{ fontSize: isMobile ? '40px' : '56px', mb: 2 }} />
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {condition.label}
                </Typography>
                <Typography sx={{ fontSize: isMobile ? '12px' : '14px', opacity: 0.9, textAlign: 'center' }}>
                  {condition.description}
                </Typography>
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  );

  const renderBrandsScreen = () => (
    <Box sx={styles.conditionContainer}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: 'bold', color: '#1a365d' }}>
        Selecciona la marca
      </Typography>
      <Grid container spacing={2}>
        {CAR_BRANDS.map((brand) => (
          <Grid item xs={6} sm={4} md={3} key={brand.label}>
            <Button
              onClick={() => handleBrandSelect(brand.label)}
              sx={styles.brandButton}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '24px', mb: 1 }}>{brand.logo}</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{brand.label}</Typography>
              </Box>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderResultsScreen = () => (
    <>
      <Box sx={{ mb: 3 }}>
        <Search
          data={cars}
          onSearchResults={(results) => {
            const filteredResults = results.filter(
              (car) =>
                (!selectedCondition || car.condition?.toLowerCase() === selectedCondition.toLowerCase()) &&
                (!selectedBrand || car.marca?.toLowerCase() === selectedBrand.toLowerCase())
            );
            setFilteredCars(filteredResults);
          }}
          onClearSearch={() => {
            const filtered = cars.filter(
              (car) =>
                (!selectedCondition || car.condition?.toLowerCase() === selectedCondition.toLowerCase()) &&
                (!selectedBrand || car.marca?.toLowerCase() === selectedBrand.toLowerCase())
            );
            setFilteredCars(filtered);
          }}
          searchKeys={['title', 'marca', 'modelo', 'description']}
          placeholder="Buscar por título, marca, modelo o descripción"
        />
      </Box>

      <Grid container spacing={isMobile ? 1 : 2}>
        {filteredCars.length > 0 ? (
          filteredCars.map((car) => (
            <Grid item xs={12} sm={6} md={4} key={car.id}>
              <Box sx={{ border: '1px solid #ddd', p: 2, borderRadius: 2 }}>
                {car.imageUrls?.[0] && (
                  <CardMedia
                    component="img"
                    height={isMobile ? '150' : '200'}
                    image={car.imageUrls[0]}
                    alt={car.title}
                  />
                )}
                <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{car.title}</Typography>
                <Typography>Precio: ${car.price}</Typography>
                <Typography>Marca: {car.marca}</Typography>
                <Typography>Modelo: {car.modelo}</Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={() => navigate(`/autos/ver/${car.id}`)}
                >
                  Ver más
                </Button>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography sx={{ p: 2 }}>No se encontraron vehículos con los filtros seleccionados.</Typography>
        )}
      </Grid>
    </>
  );

  const renderScreen = () => {
    switch (screen) {
      case 'carCondition':
        return renderConditionScreen();
      case 'brands':
        return renderBrandsScreen();
      case 'results':
        return renderResultsScreen();
      default:
        return renderConditionScreen();
    }
  };

  return (
    <Container>
      <AppBar style={styles.header}>
        <Toolbar sx={{ flexDirection: 'column', bgcolor: 'white' }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Logo"
            style={styles.logo}
          />
          <Typography variant={isMobile ? 'h6' : 'h5'} color="black">
            {screen === 'carCondition'
              ? 'Selecciona condición del vehículo'
              : screen === 'brands'
              ? 'Selecciona marca'
              : 'Resultados'}
          </Typography>
          {screen !== 'carCondition' && (
            <Button
              onClick={() =>
                setScreen(screen === 'results' ? 'brands' : 'carCondition')
              }
              sx={{ mt: 1, mb: 1 }}
            >
              Volver
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 20, mb: 10 }}>{renderScreen()}</Box>

      <BottomNav />
    </Container>
  );
};

export default CarsPage;