import React, { useRef, useState, useCallback } from "react";
import {
  Box,
  Fab,
  TextField,
  IconButton,
  Paper,
  Typography,
  Button,
  Zoom,
  Alert,
  Snackbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UniversalSearch = ({
  data = [],
  onSearchResults = () => {},
  onClearSearch = () => {}, // Nueva prop para manejar la limpieza
  searchKeys = ['name', 'title', 'description', 'product'],
  placeholder = "Buscar producto o tienda",
  noResultsTitle = "No encontramos resultados",
  noResultsMessage = "No hay productos o tiendas que coincidan con tu búsqueda",
  buttonPosition = { top: 160, right: 16 }
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const searchTimeout = useRef(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchActive, setSearchActive] = useState(false);

  

  const styles = {
    searchFab: {
      position: "fixed",
      zIndex: 1000,
      ...buttonPosition,
    },
    searchContainer: {
      position: "fixed",
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
      padding: "8px 16px",
      transition: "all 0.3s ease",
      ...buttonPosition,
    },
    searchInput: {
      marginRight: "8px",
      border: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    noResults: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      textAlign: "center",
      marginTop: "2rem",
      backgroundColor: "#f5f5f5",
      borderRadius: "8px",
      gap: "1rem",
    },
    noResultsIcon: {
      fontSize: "4rem",
      color: "#9e9e9e",
    },
    resultsFound: {
      position: "fixed",
      top: buttonPosition.top + 60,
      right: buttonPosition.right,
      zIndex: 1000,
      backgroundColor: "#4caf50",
      color: "white",
      padding: "8px 16px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
    },
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      handleSearch(value);
    }, 300);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const normalizeText = (text) => {
    if (!text) return '';
    return text.toString()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim();
  };

  const handleSearch = useCallback((searchValue) => {
    if (!Array.isArray(data)) {
      console.error("data is not an array:", data);
      return;
    }

    const value = normalizeText(searchValue);
    
    if (value === "") {
      onClearSearch();
      setHasSearched(false);
      setShowSnackbar(false);
      setSearchActive(false);
      return;
    }

    setSearchActive(true);
    
    const filtered = data.filter((item) => {
      return searchKeys.some(key => {
        const itemValue = item[key];
        if (!itemValue) return false;
        const normalizedItemValue = normalizeText(itemValue);
        const searchTerms = value.split(/\s+/);
        return searchTerms.every(term => normalizedItemValue.includes(term));
      });
    });

    setFilteredResults(filtered);
    onSearchResults(filtered);
    setHasSearched(true);
    
    if (filtered.length > 0) {
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 3000);
    }
  }, [data, searchKeys, onSearchResults, onClearSearch, normalizeText]);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        document.getElementById('universal-search-input')?.focus();
      }, 100);
    } else {
      setSearchTerm("");
      onClearSearch();
      setHasSearched(false);
      setShowSnackbar(false);
      setSearchActive(false);
    }
  };

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    onClearSearch();
    setHasSearched(false);
    setShowSnackbar(false);
    setSearchActive(false);
  }, [onClearSearch]);

  // Solo mostrar NoResults cuando la búsqueda está activa y no hay resultados
  const shouldShowNoResults = searchActive && hasSearched && searchTerm.length > 0 && filteredResults.length === 0;

  const NoResults = () => (
    <Paper sx={styles.noResults}>
      <SentimentDissatisfiedIcon sx={styles.noResultsIcon} />
      <Typography variant="h6">{noResultsTitle}</Typography>
      <Typography color="textSecondary">
        {noResultsMessage} "{searchTerm}"
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClearSearch}>
        Limpiar búsqueda
      </Button>
    </Paper>
  );

  const ResultsFound = () => (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={3000}
      onClose={() => setShowSnackbar(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert icon={<CheckCircleIcon />} severity="success" variant="filled">
        {`Se encontraron ${filteredResults.length} resultado${
          filteredResults.length === 1 ? "" : "s"
        }`}
      </Alert>
    </Snackbar>
  );

  return (
    <Box>
      <Zoom in={!isSearchOpen}>
        <Fab
          color="primary"
          aria-label="search"
          onClick={toggleSearch}
          sx={styles.searchFab}
        >
          <SearchIcon />
        </Fab>
      </Zoom>

      <Zoom in={isSearchOpen}>
        <Paper sx={{ ...styles.searchContainer, minWidth: "280px" }}>
          <TextField
            id="universal-search-input"
            placeholder={placeholder}
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={styles.searchInput}
          />
          <IconButton onClick={toggleSearch} size="small">
            <CloseIcon />
          </IconButton>
        </Paper>
      </Zoom>

      {shouldShowNoResults && <NoResults />}
      <ResultsFound />
    </Box>
  );
};

export default UniversalSearch;
