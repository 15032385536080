import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Avatar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from '../firebase';

const BottomToolbar = () => {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  useEffect(() => {
    const unsubscribeNotifications = subscribeToNotifications(setHasNewNotifications);
    
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            if (userData.profileImageUrl) {
              setProfileImageUrl(userData.profileImageUrl);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setProfileImageUrl(null);
      }
    });

    return () => {
      unsubscribeNotifications();
      unsubscribeAuth();
    };
  }, []);

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      position: "fixed",
    },
    toolbar: {
      justifyContent: 'space-around',
      backgroundColor: 'white',
    },
    iconButton: {
      display: "flex",
      justifyContent: "center",
      color: 'grey',
    },
    icon: {
      color: 'grey',
    },
    avatar: {
      width: 24,
      height: 24,
      border: "2.3px solid grey"
    }
  };

  return (
    <AppBar position="fixed" sx={classes.appBar}>
      <Toolbar sx={classes.toolbar}>
        <IconButton href="/home" sx={classes.iconButton}>
          <HomeIcon sx={classes.icon} />
        </IconButton>

        <IconButton href="/members" sx={classes.iconButton}>
          <SearchIcon sx={classes.icon} />
        </IconButton>

        <IconButton href="/notifications" sx={classes.iconButton}>
          <NotificationIcon hasNewNotifications={hasNewNotifications} sx={classes.icon} />
        </IconButton>

        <IconButton href="/profile" sx={classes.iconButton}>
          {profileImageUrl ? (
            <Avatar src={profileImageUrl} sx={classes.avatar} />
          ) : (
            <Avatar sx={classes.avatar} />
          )}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default BottomToolbar;