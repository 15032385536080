import React, { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Home, Business, Villa } from "@mui/icons-material";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import logoSvg from "../../imgs/logo.svg";
import BottomNav from "./BottomNav";
import Search from "../search";

const PROPERTY_TYPES = [
  {
    id: "alojamiento",
    label: "Alojamiento",
    color: "#4a90e2",
    gradient: "linear-gradient(135deg, #4a90e2 0%, #357abd 100%)",
    icon: Home,
    description: "Hoteles, hostales y alojamientos temporales",
  },
  {
    id: "compra",
    label: "Compra",
    color: "#48bb78",
    gradient: "linear-gradient(135deg, #48bb78 0%, #38a169 100%)",
    icon: Villa,
    description: "Casas y propiedades en venta",
  },
  {
    id: "alquiler",
    label: "Alquiler",
    color: "#ed8936",
    gradient: "linear-gradient(135deg, #ed8936 0%, #dd6b20 100%)",
    icon: Business,
    description: "Departamentos y propiedades en alquiler",
  },
];

const REGIONS = [
  {
    label: "América del Norte",
    coordinates: { lat: 40, lng: -100 },
    color: "#4a90e2",
  },
  {
    label: "América del Sur",
    coordinates: { lat: -15, lng: -60 },
    color: "#48bb78",
  },
  { label: "Europa", coordinates: { lat: 50, lng: 10 }, color: "#ed8936" },
  { label: "Asia", coordinates: { lat: 35, lng: 105 }, color: "#9c27b0" },
  {
    label: "Medio Oriente",
    coordinates: { lat: 27, lng: 45 },
    color: "#f50057",
  }, // Added Middle East
];

const ViajesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [screen, setScreen] = useState("propertyTypes");
  const [places, setPlaces] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [globeData, setGlobeData] = useState([]);
  const globeEl = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlaces = async () => {
      const placesCollectionRef = collection(db, "places");
      const unsubscribe = onSnapshot(
        query(placesCollectionRef, orderBy("createdAt", "desc")),
        (snapshot) => {
          const placesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            imageUrls: Array.isArray(doc.data().images)
              ? doc.data().images
              : [doc.data().images].filter(Boolean),
          }));
          setPlaces(placesData);

          const globePoints = REGIONS.map((region) => ({
            lat: region.coordinates.lat,
            lng: region.coordinates.lng,
            name: region.label,
            radius: isMobile ? 4 : 3,
            color: region.color,
            altitude: 0.1,
          }));
          setGlobeData(globePoints);
        }
      );
      return () => unsubscribe();
    };
    fetchPlaces();
  }, [isMobile]);

  useEffect(() => {
    if (globeEl.current) {
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 0.5;
      globeEl.current.pointOfView({ altitude: isMobile ? 3 : 2.5 });
    }
  }, [isMobile]);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setScreen("regions");
  };

  const handleRegionSelect = (point) => {
    console.log("Selected region:", point.name);
    setSelectedRegion(point.name);
    setScreen("results");
  };

  useEffect(() => {
    if (!places.length) return;

    const filtered = places.filter(
      (place) =>
        (!selectedType ||
          place.category?.toLowerCase() === selectedType.toLowerCase()) &&
        (!selectedRegion ||
          place.place?.toLowerCase().includes(selectedRegion.toLowerCase()))
    );
    setFilteredPlaces(filtered);

    // Log para depuración
    console.log("Filtros aplicados:", {
      selectedType,
      selectedRegion,
      totalPlaces: places.length,
      filteredCount: filtered.length,
    });
  }, [places, selectedType, selectedRegion]);

  const GlobeComponent = () => {
    const globeWidth = isMobile ? window.innerWidth - 40 : 800;
    const globeHeight = isMobile ? 400 : 600;

    return (
      <div
        className="flex justify-center items-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: isMobile ? "50%" : "70%",
        }}
      >
        <Globe
          ref={globeEl}
          width={globeWidth}
          height={globeHeight}
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
          bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
          pointsData={globeData}
          pointLabelHtml={(d) => `
            <div style="
              background-color: rgba(0, 0, 0, 0.75);
              color: white;
              padding: 5px;
              border-radius: 5px;
              font-size: 14px;
              width: auto;
              text-align: center;
            ">
              ${d.name}
            </div>
          `}
          pointRadius="radius"
          pointColor="color"
          pointAltitude="altitude"
          atmosphereColor="#ffffff"
          atmosphereAltitude={0.25}
          backgroundColor="#f8fafc"
          pointsMerge={false}
          onPointClick={handleRegionSelect}
        />
      </div>
    );
  };

  const styles = {
    header: {
      position: "fixed",
      top: 0,
      width: "100%",
      bgcolor: "white",
      zIndex: 1100,
    },
    logo: {
      width: isMobile ? "120px" : "120px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    typeButton: {
      width: "100%",
      height: isMobile ? "100px" : "150px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      margin: "10px 0",
      color: "white",
      transition: "transform 0.2s",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    typeButton: {
      width: "100%",
      minHeight: isMobile ? "160px" : "200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "16px",
      padding: "20px",
      color: "white",
      transition: "all 0.3s ease",
      position: "relative",
      overflow: "hidden",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 8px 12px rgba(0,0,0,0.15)",
      },
    },
    typeIcon: {
      fontSize: isMobile ? "40px" : "56px",
      marginBottom: "16px",
    },
    typeDescription: {
      fontSize: isMobile ? "12px" : "14px",
      opacity: 0.9,
      textAlign: "center",
      marginTop: "8px",
    },
    propertyTypeContainer: {
      padding: isMobile ? "20px" : "40px",
      backgroundColor: "#f5f7fa",
      borderRadius: "24px",
      margin: "0 auto",
      maxWidth: "1200px",
    },
  };

  const renderPropertyTypeScreen = () => (
    <Box sx={styles.propertyTypeContainer}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          mb: 4,
          fontWeight: "bold",
          color: "#1a365d",
        }}
      >
        ¿Qué estás buscando?
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {PROPERTY_TYPES.map((type) => {
          const IconComponent = type.icon;
          return (
            <Grid item xs={12} sm={4} key={type.id}>
              <Button
                onClick={() => handleTypeSelect(type.id)}
                style={{
                  ...styles.typeButton,
                  background: type.gradient,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0.1,
                    background:
                      "radial-gradient(circle at top right, white 0%, transparent 60%)",
                  }}
                />
                <IconComponent sx={styles.typeIcon} />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {type.label}
                </Typography>
                <Typography style={styles.typeDescription}>
                  {type.description}
                </Typography>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  const renderScreen = () => {
    // eslint-disable-next-line default-case
    switch (screen) {
      case "propertyTypes":
        return renderPropertyTypeScreen();

      case "regions":
        return (
          <>
            <GlobeComponent />
            <Box sx={{ mt: 5, mb: 5, textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                Toca un punto en el globo para seleccionar la región
              </Typography>
            </Box>
          </>
        );

      case "results":
        return (
          <>
            <Box sx={{ mb: 3 }}>
              <Search
                data={places}
                onSearchResults={(results) => {
                  // Aplicar los filtros de tipo y región a los resultados de búsqueda
                  const filteredResults = results.filter(
                    (place) =>
                      (!selectedType ||
                        place.category?.toLowerCase() ===
                          selectedType.toLowerCase()) &&
                      (!selectedRegion ||
                        place.place
                          ?.toLowerCase()
                          .includes(selectedRegion.toLowerCase()))
                  );
                  setFilteredPlaces(filteredResults);
                }}
                onClearSearch={() => {
                  // Volver a aplicar solo los filtros de tipo y región
                  const filtered = places.filter(
                    (place) =>
                      (!selectedType ||
                        place.category?.toLowerCase() ===
                          selectedType.toLowerCase()) &&
                      (!selectedRegion ||
                        place.place
                          ?.toLowerCase()
                          .includes(selectedRegion.toLowerCase()))
                  );
                  setFilteredPlaces(filtered);
                }}
                searchKeys={["title", "city", "description"]}
                placeholder="Buscar por título, ciudad o descripción"
              />
            </Box>

            <Grid container spacing={isMobile ? 1 : 2}>
              {filteredPlaces.length > 0
                ? filteredPlaces.map((place) => (
                    <Grid item xs={12} sm={6} md={4} key={place.id}>
                      <Box
                        sx={{ border: "1px solid #ddd", p: 2, borderRadius: 2 }}
                      >
                        {place.imageUrls?.[0] && (
                          <CardMedia
                            component="img"
                            height={isMobile ? "150" : "200"}
                            image={place.imageUrls[0]}
                            alt={place.title}
                          />
                        )}
                        <Typography variant={isMobile ? "subtitle1" : "h6"}>
                          {place.title}
                        </Typography>
                        <Typography>Precio: ${place.price}</Typography>
                        <Typography>Ciudad: {place.city}</Typography>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{ mt: 1 }}
                          onClick={() => navigate(`/viajes/ver/${place.id}`)}
                        >
                          Ver más
                        </Button>
                      </Box>
                    </Grid>
                  ))
                : null}
            </Grid>
          </>
        );
    }
  };

  return (
    <Container>
      <AppBar style={styles.header}>
        <Toolbar sx={{ flexDirection: "column", bgcolor: "white" }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Logo"
            style={styles.logo}
          />
          <Typography variant={isMobile ? "h6" : "h5"} color="black">
            {screen === "propertyTypes"
              ? "Selecciona tipo de propiedad"
              : screen === "regions"
              ? "Selecciona región"
              : "Resultados"}
          </Typography>
          {screen !== "propertyTypes" && (
            <Button
              onClick={() =>
                setScreen(screen === "results" ? "regions" : "propertyTypes")
              }
              sx={{ mt: 1, mb: 1 }}
            >
              Volver
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 20, mb: 10 }}>{renderScreen()}</Box>

      <BottomNav />
    </Container>
  );
};

export default ViajesPage;
