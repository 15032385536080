import React, { useState, useEffect } from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isLoggedIn } from "../session";
import { useNavigate } from "react-router-dom";
import Atras from "./atras";

function HeaderPerfil() {
  const { uid } = useParams();
  const [userId, setUserId] = useState(""); // Estado para almacenar el nombre del usuario

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const db = getFirestore();
        const userDocRef = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const name = userData.displayName || "User"; // Asegurando que 'displayName' sea el campo correcto
          setUserId(name);
        } else {
          console.error("No se encontró el usuario.");
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      }
    };

    if (uid) {
      fetchUserData();
    }
  }, [uid]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "#312783",
        }}
      >
        <Atras />
        {userId && (
          <Typography
            variant="h6"
            sx={{
              paddingLeft: "24px",
              paddingTop: "10px",
              fontSize: "24px",
              color: "white",
            }}
            gutterBottom
          >
            {userId}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default HeaderPerfil;
