import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getToken } from "./firebase";

const auth = getAuth();

let currentUser = null; // Variable global para almacenar el usuario actual

// Función para obtener la sesión actual
export const getSession = () => {
  return new Promise((resolve, reject) => {
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe();

        if (user) {
          getToken(user)
            .then((idToken) => {
              currentUser = {
                email: user.email,
                uid: user.uid,
                idToken: idToken,
                displayName: user.displayName,
                photoURL: user.profileImageUrl,  // Add this line
              };
              resolve(currentUser);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(null);
        }
      }, (error) => {
        reject(error);
      });
    }
  });
};

// Función para iniciar sesión
export const startSession = () => {
  // Lógica para iniciar sesión
};

// Función para cerrar sesión
export const endSession = async () => {
  try {
    // Cerrar sesión del usuario actual
    await signOut(auth);
    // Limpiar el usuario actual
    currentUser = null;
    // Si el cierre de sesión es exitoso, devolver true
    return true;
  } catch (error) {
    // Si hay algún error, lanzar una excepción con el mensaje de error
    throw new Error(error.message);
  }
};

// Función para verificar si el usuario está autenticado
export const isLoggedIn = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};
