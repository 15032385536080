import { useEffect } from 'react';
import { auth, db } from '../firebase'; // Importaciones de Firebase
import { query, collection, where, onSnapshot, updateDoc } from 'firebase/firestore';
import Badge from '@mui/material/Badge'; // Importaciones de Material-UI
import NotificationsIcon from '@mui/icons-material/Notifications'; // Importación del icono de notificaciones
import { useLocation } from 'react-router-dom'; // Importación para obtener la ubicación actual

// Función para actualizar todas las notificaciones a read: true
const markAllNotificationsAsRead = () => {
  const user = auth.currentUser;
  if (user) {
    const notificationsRef = collection(db, 'notifications');
    const userNotificationsQuery = query(
      notificationsRef,
      where('userId', '==', user.uid),
      where('read', '==', false)
    );

    // Obtener las notificaciones pendientes del usuario y marcarlas como leídas
    onSnapshot(userNotificationsQuery, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        const notificationRef = doc.ref;
        updateDoc(notificationRef, {
          read: true
        }).catch((error) => {
          console.error('Error marking notification as read:', error);
        });
      });
    });
  }
};


// Función de suscripción a notificaciones
export const subscribeToNotifications = (callback) => {
  const user = auth.currentUser;
  if (user) {
    const notificationsRef = collection(db, 'notifications');
    const userNotificationsQuery = query(
      notificationsRef,
      where('userId', '==', user.uid),
      where('read', '==', false)
    );

    // Obtener las notificaciones pendientes del usuario
    const unsubscribe = onSnapshot(userNotificationsQuery, (snapshot) => {
      console.log('Notifications snapshot: ', snapshot.size);
      callback(snapshot.size > 0);
    });

    return unsubscribe;
  }
  console.error('No user is currently authenticated for notifications subscription');
  return null;
};

// Componente NotificationIcon
const NotificationIcon = ({ hasNewNotifications }) => {
  const location = useLocation(); // Obtener la ubicación actual
  const currentPage = location.pathname; // Obtener la página actual

  useEffect(() => {
    if (currentPage === '/notifications') {
      markAllNotificationsAsRead();
    }
  }, [currentPage]);

  return (
    <Badge color="primary" variant="dot" invisible={!hasNewNotifications}>
      <NotificationsIcon />
    </Badge>
  );
};

export default NotificationIcon;
