import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const CopyToClipboardComponent = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);

    // Restablecer el estado después de un breve período para mostrar el estado de copiado
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Box sx={{display: "block", width: "100%"}}>
      <Typography
        className="item"
        variant="body2"
        color="textSecondary"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "auto",
          height: "auto",
          color: "white",
          padding: "8px",
          marginBottom: "15px",
          borderRadius: "5px",
          bgcolor: "lightseagreen",
          fontSize: "18px",
        }}
      >
        Cupón: {textToCopy}
        <Button
          variant="outlined"
          onClick={copyToClipboard}
          sx={{ marginTop: "0px", color: "white", borderColor: "white" }}
        >
          {isCopied ? "Copiado" : "Copiar"}
        </Button>
      </Typography>
    </Box>
  );
};

export default CopyToClipboardComponent;
