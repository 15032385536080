import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Container,
  Badge,
  CircularProgress,
  Tabs,
  Tab,
  Chip
} from "@mui/material";
import { isLoggedIn } from "../session";
import BottomNav from "./BottomToolbar";
import Atras from "./atras";

function Chats() {
  const [chats, setChats] = useState([]);
  const [authUserId, setAuthUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const user = await isLoggedIn();
      if (user) {
        setAuthUserId(user.uid);
      } else {
        navigate("/login");
      }
    };
    fetchAuthUserId();
  }, [navigate]);

  useEffect(() => {
    const fetchChats = async () => {
      if (!authUserId) return;

      setLoading(true);
      try {
        const conversationsRef = collection(db, "conversations");
        const conversationsQuery = query(
          conversationsRef,
          where("participants", "array-contains", authUserId)
        );
        const conversationsSnapshot = await getDocs(conversationsQuery);

        const chatPromises = conversationsSnapshot.docs.map(
          async (conversationDoc) => {
            const conversationData = conversationDoc.data();
            const otherUserId = conversationData.participants.find(
              (id) => id !== authUserId
            );

            // Fetch the last message
            const messagesRef = collection(
              db,
              `conversations/${conversationDoc.id}/messages`
            );
            const lastMessageQuery = query(
              messagesRef,
              orderBy("timestamp", "desc"),
              limit(1)
            );
            const lastMessageSnapshot = await getDocs(lastMessageQuery);

            const lastMessage = lastMessageSnapshot.docs[0]?.data() || {};

            // Fetch item details based on type
            let itemData = {};
            if (conversationData.type && conversationData.itemId) {
              let itemDocRef;
              switch (conversationData.type) {
                case "auto":
                  itemDocRef = doc(db, "cars", conversationData.itemId);
                  break;
                case "viaje":
                  itemDocRef = doc(db, "places", conversationData.itemId);
                  break;
                default:
                  console.warn(
                    `Tipo de conversación desconocido: ${conversationData.type}`
                  );
              }
              if (itemDocRef) {
                const itemDocSnapshot = await getDoc(itemDocRef);
                itemData = itemDocSnapshot.exists()
                  ? itemDocSnapshot.data()
                  : {};
              }
            }

            // Fetch other user's display name
            let otherUserDisplayName = "Usuario desconocido";
            if (otherUserId) {
              const otherUserDocRef = doc(db, "users", otherUserId);
              const otherUserDocSnapshot = await getDoc(otherUserDocRef);
              if (otherUserDocSnapshot.exists()) {
                otherUserDisplayName =
                  otherUserDocSnapshot.data().displayName ||
                  otherUserDisplayName;
              }
            }

            // Calculate unread messages count
            const unreadCount = conversationData.unreadCount?.[authUserId] || 0;

            return {
              id: conversationDoc.id,
              type: conversationData.type || "desconocido",
              title: itemData.title || "Sin título",
              imageUrl: itemData.images?.[0] || "",
              lastMessage: lastMessage,
              itemId: conversationData.itemId || "",
              otherUserId: otherUserId || "",
              otherUserDisplayName: otherUserDisplayName,
              unreadCount,
            };
          }
        );

        const chatResults = await Promise.all(chatPromises);
        setChats(chatResults);
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [authUserId]);

  const handleChatClick = (chatId, type, itemId, otherUserId) => {
    navigate(`/chatbox/${type}/${itemId}/${otherUserId}`);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredChats = chats.filter((chat) => {
    if (activeTab === 0) return true; // All chats
    if (activeTab === 1) return chat.type === "auto";
    if (activeTab === 2) return chat.type === "viaje";
    return false;
  });

  return (
    <Box sx={{ mt: 10 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Atras />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Chats
          </Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Todos" />
        <Tab label="Autos" />
        <Tab label="Viajes" />
      </Tabs>
      <Container sx={{ mt: 3 }}>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : filteredChats.length > 0 ? (
          <List>
            {filteredChats.map((chat) => (
              <React.Fragment key={chat.id}>
                <ListItem
                  button
                  onClick={() =>
                    handleChatClick(
                      chat.id,
                      chat.type,
                      chat.itemId,
                      chat.otherUserId
                    )
                  }
                >
                  <Badge
                    badgeContent={chat.unreadCount}
                    color="error"
                    invisible={chat.unreadCount === 0}
                  >
                    <Avatar src={chat.imageUrl} alt={chat.title} />
                  </Badge>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ marginRight: 8 }}>
                          {chat.title}
                        </Typography>
                        <Chip
                          label={chat.type === "auto" ? "Auto" : "Viaje"}
                          color={chat.type === "auto" ? "primary" : "secondary"}
                          size="small"
                        />
                      </Box>
                    }
                    secondary={`${chat.otherUserDisplayName}: ${
                      chat.lastMessage.content || ""
                    }`}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ mt: 5, textAlign: "center" }}>
            No hay chats disponibles.
          </Typography>
        )}
      </Container>
      <BottomNav />
    </Box>
  );
}

export default Chats;
