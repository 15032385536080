import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { signInUser, resetPassword, signInWithGoogle } from '../firebase';
import { isLoggedIn } from '../session';
import logoSvg from "../imgs/logo.svg";

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    if (error) setError('');
  };

  const validateForm = () => {
    if (!formData.email || !formData.password) {
      setError('Por favor ingrese su usuario y contraseña.');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Por favor ingrese un email válido.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const loginResponse = await signInUser(formData.email, formData.password);
      isLoggedIn(loginResponse.user);
      navigate('/home');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithGoogle();
      isLoggedIn(result.user);
      navigate(result.isNewUser ? '/edit' : '/home');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!formData.email) {
      setError('Por favor ingrese su dirección de correo electrónico.');
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword(formData.email);
      alert('Email de restablecimiento de contraseña enviado con éxito.');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Card 
        elevation={3}
        sx={{
          mt: 8,
          pb: 4,
          borderRadius: 2
        }}
      >
        <CardMedia
          component="img"
          image={logoSvg}
          title="Notiline"
          sx={{
            width: 180,
            height: 'auto',
            mx: 'auto',
            mt: 4,
            mb: 2
          }}
        />
        
        <CardContent>
          <Typography 
            variant="h5" 
            component="h1" 
            textAlign="center"
            fontWeight="500"
            gutterBottom
          >
            Inicio de sesión
          </Typography>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1
              }}
            >
              {error}
            </Alert>
          )}

          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            noValidate
          >
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                type="email"
                label="Email"
                value={formData.email}
                onChange={handleChange('email')}
                placeholder="usuario@gmail.com"
                disabled={isLoading}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                label="Contraseña"
                value={formData.password}
                onChange={handleChange('password')}
                placeholder="••••••••••••"
                disabled={isLoading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      disabled={isLoading}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ 
                mt: 3,
                height: 42,
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Iniciar sesión'
              )}
            </Button>

            <Box sx={{ my: 2, position: 'relative' }}>
              <Divider>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    px: 1 
                  }}
                >
                  o
                </Typography>
              </Divider>
            </Box>

            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignIn}
              disabled={isLoading}
              startIcon={
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  style={{ width: 18, height: 18 }}
                />
              }
              sx={{
                height: 42,
                textTransform: 'none',
                fontSize: '1rem',
                borderColor: 'divider',
                color: 'text.secondary',
                '&:hover': {
                  borderColor: 'divider',
                  bgcolor: 'action.hover'
                }
              }}
            >
              Continuar con Google
            </Button>

            <Stack 
              spacing={1} 
              alignItems="center" 
              sx={{ mt: 2 }}
            >
              <Link
                component="button"
                variant="body2"
                onClick={handleForgotPassword}
                disabled={isLoading}
                sx={{ 
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Olvidé mi contraseña
              </Link>

              <Typography variant="body2" color="text.secondary">
                ¿Aún no tienes cuenta?{' '}
                <Link 
                  href="/register"
                  sx={{ 
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  Registrarse
                </Link>
              </Typography>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;