import React, { useState, useEffect } from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isLoggedIn } from "../../session";
import { useNavigate } from "react-router-dom";
import Atras from "../atras";

function Header() {
  const { carId } = useParams();
  const [carName, setCarName] = useState(""); // Corrected state name

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const db = getFirestore();
        const carDocRef = doc(db, "cars", carId);
        const carDocSnapshot = await getDoc(carDocRef);

        if (carDocSnapshot.exists()) {
          const carData = carDocSnapshot.data();
          const name = carData.title || "Vehiculo"; // Adjusted to match your data structure
          setCarName(name);
        } else {
          console.error("No se encontro el vehiculo.");
        }
      } catch (error) {
        console.error("Error fetching car data:", error);
      }
    };

    if (carId) {
      fetchCarData();
    }
  }, [carId]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);
  const styles = {
    appBar: {
      bgcolor: "#312783",
    },
  }

  return (
    <AppBar position="fixed" sx={styles.appBar}>
      <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", height: "100%" }}>
        <Atras />
        {carName && (
          <Typography
            variant="h6"
            sx={{ paddingLeft: "24px", paddingTop: "10px", fontSize: "14px", color: "white" }}
            gutterBottom
          >
            {carName}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
