import React, { useState, useEffect } from "react";
import {
  Container,
  CardMedia,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  doc,
  getDoc,
  collection,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import Header3 from "./Header3";
import BottomNav from "./BottomNav";
import { isLoggedIn } from "../../session";

const VerCar = () => {
  const { carId } = useParams();
  const [carData, setCarData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [authUserId, setAuthUserId] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const user = await isLoggedIn();
      if (user) {
        setAuthUserId(user.uid);
      } else {
        navigate("/login");
      }
    };
    fetchAuthUserId();
  }, [navigate]);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const carDocRef = doc(db, "cars", carId);
        const carDoc = await getDoc(carDocRef);

        if (carDoc.exists()) {
          setCarData({ id: carDoc.id, ...carDoc.data() });
        } else {
          console.error("No se encontró el vehículo.");
        }
      } catch (error) {
        console.error("Error al obtener el vehículo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCar();
  }, [carId]);

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate instanceof Function) {
      return timestamp.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleOpenImageModal = () => {
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === carData.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? carData.images.length - 1 : prevIndex - 1
    );
  };

  const handleChatClick = async () => {
    if (!authUserId || !carData) return;

    try {
      const conversationsRef = collection(db, "conversations");
      const conversationId = [authUserId, carData.uid].sort().join("_");
      const conversationDocRef = doc(conversationsRef, conversationId);
      const conversationDoc = await getDoc(conversationDocRef);

      if (!conversationDoc.exists()) {
        // Create a new conversation if it doesn't exist
        await setDoc(conversationDocRef, {
          participants: [authUserId, carData.uid],
          type: "auto",
          itemId: carData.id,
          lastMessage: {
            content: "",
            timestamp: serverTimestamp(),
            senderId: authUserId,
          },
          unreadCount: {
            [authUserId]: 0,
            [carData.uid]: 0,
          },
        });
      }

      // Navigate to the unified chat box
      navigate(`/chatbox/auto/${carData.id}/${carData.uid}`);
    } catch (error) {
      console.error("Error al iniciar la conversación:", error);
    }
  };

  const styles = {
    card: {
      padding: "20px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
    description: {
      fontSize: "14px",
      marginBottom: "8px",
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#4CAF50",
      marginBottom: "8px",
    },
    place: {
      fontSize: "14px",
      color: "dodgerblue",
      marginBottom: "8px",
    },
    created: {
      fontSize: "12px",
      color: "#999",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "300px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "80px",
      height: "80px",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "80%",
      maxHeight: "80%",
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
    },
    imageModalNavigation: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!carData) {
    return (
      <Container>
        <Typography>No se encontró el Vehículo.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Header3 />
      <Container sx={{ mt: 10, mb: 20 }}>
        <Grid container spacing={2} sx={{ mb: 20 }}>
          <Grid item xs={12}>
            <Box style={styles.card}>
              <Typography sx={styles.title}>{carData.title}</Typography>
              <Box sx={styles.contentContainer}>
                <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
                  Condicion: {carData.condition}
                </Typography>
                <Typography sx={styles.description}>
                  {carData.description}
                </Typography>
                <Typography sx={styles.price}>
                  Precio: $ {carData.price}
                </Typography>
                <Typography sx={styles.modelo}>
                  Ciudad: {carData.city}
                </Typography>
                <Typography sx={styles.modelo}>
                  Modelo: {carData.año}
                </Typography>
                <Typography sx={styles.modelo}>
                  Kilometros: {carData.km} km
                </Typography>
                <Typography sx={styles.marca}>
                  Marca: {carData.marca}
                </Typography>
                <Typography sx={styles.marca}>
                  Linea: {carData.modelo}
                </Typography>
                <Typography sx={styles.created}>
                  Fecha de publicación: {formatDate(carData.createdAt)}
                </Typography>

                <Box style={styles.imageContainer}>
                  {carData.images && carData.images.length > 0 ? (
                    <>
                      <CardMedia
                        component="img"
                        image={carData.images[0]}
                        alt={`Imagen principal de ${carData.title}`}
                        style={styles.mainImage}
                      />
                      {carData.images.length > 1 && (
                        <Box
                          style={styles.additionalImagesOverlay}
                          onClick={handleOpenImageModal}
                        >
                          <Typography style={styles.additionalImagesCount}>
                            +{carData.images.length - 1}
                          </Typography>
                          <Typography>Ver más</Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box style={styles.noImage}>
                      <Typography>No hay imágenes disponibles</Typography>
                    </Box>
                  )}
                </Box>
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  fullWidth
                  color="primary"
                  onClick={handleChatClick}
                  disabled={authUserId === carData.uid}
                >
                  {authUserId === carData.uid
                    ? "Este es tu anuncio"
                    : "Consultar"}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Image Modal */}
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del Vehículo
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {carData.images && carData.images.length > 1 && (
                <Box style={styles.imageModalContent}>
                  <CardMedia
                    component="img"
                    image={carData.images[currentImageIndex]}
                    alt={`Imagen ${currentImageIndex + 1} del vehículo`}
                    style={styles.imageModalImage}
                  />
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
      <BottomNav />
    </Container>
  );
};

export default VerCar;
