import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from "@mui/material";
import { ChevronLeftRounded as ChevronLeftRoundedIcon } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const QRPaymentProcessor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [scannedQR, setScannedQR] = useState(null);
  const [storeName, setStoreName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStoreDetails = async () => {
      try {
        if (!location.state?.couponData) {
          navigate("/home");
          return;
        }

        const qrData = location.state.couponData;
        console.log("QR Data:", qrData); // Para debugging
        setScannedQR(qrData);

        try {
          const merchantDoc = await getDoc(doc(db, "users", qrData.merchantId));
          if (merchantDoc.exists()) {
            setStoreName(merchantDoc.data().displayName || "Comercio");
          } else {
            setStoreName("Comercio");
          }
        } catch (err) {
          console.error("Error fetching merchant details:", err);
          setStoreName(qrData.storeName || "Comercio");
        }
      } catch (err) {
        console.error("Error processing QR data:", err);
        setError("Error al procesar los datos del QR");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStoreDetails();
  }, [location, navigate]);

  const handlePayment = () => {
    if (!scannedQR) {
      setError("No se encontraron datos de pago");
      return;
    }

    navigate("/checkout", {
      state: {
        couponData: {
          ...scannedQR,
          storeName: storeName,
        },
      },
    });
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm" sx={{ pt: 10, textAlign: "center" }}>
        <Typography variant="h6">Cargando datos de pago...</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ChevronLeftRoundedIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Confirmar Pago
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm" sx={{ pt: 10, pb: 4, mb:10 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          {scannedQR && (
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Resumen del Pago
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Typography variant="body1" gutterBottom>
                <strong>Comercio:</strong> {storeName}
              </Typography>

              {Array.isArray(scannedQR?.offers) && scannedQR.offers.length > 0 ? (
                scannedQR.offers.map((offer, index) => {
                  console.log("Offer data:", offer); // Para debugging
                  return (
                    <Card key={index} sx={{ mt: 2, bgcolor: "grey.50" }}>
                      <CardContent>
                        <Typography variant="subtitle1">
                          {typeof offer.title === 'string' 
                            ? (offer.title.length > 50
                                ? `${offer.title.substring(0, 50)}...`
                                : offer.title)
                            : "Producto sin nombre"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {typeof offer.description === 'string'
                            ? (offer.description.length > 100
                                ? `${offer.description.substring(0, 100)}...`
                                : offer.description)
                            : "Sin descripción"}
                        </Typography>
                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Precio original: ${typeof offer.price === 'number' 
                              ? offer.price.toFixed(2)
                              : parseFloat(offer.price || 0).toFixed(2)}
                          </Typography>
                          {offer.offer > 0 && (
                            <Typography variant="body2" color="success.main">
                              {offer.offer}% OFF
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  );
                })
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay ofertas disponibles
                </Typography>
              )}

              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: "primary.main",
                  color: "white",
                  borderRadius: 1,
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">
                  Total a Pagar: ${typeof scannedQR.totalAmount === 'number' 
                    ? scannedQR.totalAmount.toFixed(2)
                    : parseFloat(scannedQR.totalAmount || 0).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          )}

          <Divider sx={{ my: 3 }} />

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Button
            variant="contained"
            fullWidth
            onClick={handlePayment}
            disabled={!scannedQR}
            sx={{
              mt: 3,
              bgcolor: "#312783",
              "&:hover": {
                bgcolor: "#251e62",
              },
              height: 48,
            }}
          >
            Pagar
          </Button>
        </Paper>
      </Container>
    </Container>
  );
};

export default QRPaymentProcessor;