import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Grid,
} from "@mui/material";
import { auth, db, uploadImage, uploadVideo } from "../firebase";
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import Atras from "./atras";
import { InputAdornment } from '@mui/material';
import { useSubscriptionContext } from "./SubscriptionContext";

const containsOffensiveLanguage = (text) => {
  // Basic list of offensive words - expand as needed
  const offensiveWords = [
    "pija",
    "puto",
    "hijo de puta",
    "travesti",
    "trolo",
    "puta",
    "pijo",
    "hijo de puto",
    "gay",
    "hijo de re mil",
    "hijo de re mil puta",
    "chupa pija",
    "trolazo",
    "pene",
    "pito",
  ];
  
  // Convert to lowercase for case-insensitive matching
  const lowerText = text.toLowerCase();
  
  // Check for exact matches and partial matches
  return offensiveWords.some(word => 
    lowerText.includes(word.toLowerCase()) ||
    // Check for common letter substitutions
    lowerText.replace(/[4@]/g, 'a')
             .replace(/[3]/g, 'e')
             .replace(/[1!]/g, 'i')
             .replace(/[0]/g, 'o')
             .includes(word.toLowerCase())
  );
};

const PublishOffer = () => {
  const [formData, setFormData] = useState({
    product: "",
    description: "",
    offer: "",
    url: "",
    cupon: "",
    price: "",
    images: [],
    videos: [],
  });
  const [loading, setLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { subscription } = useSubscriptionContext(); // Correct usage


  useEffect(() => {
    if (!subscription) {
      navigate('/subscription'); // Redirige si no hay suscripción activa
    }
  }, [subscription, navigate]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
  
    // Existing validations
    Object.keys(formData).forEach((key) => {
      if (key !== "images" && key !== "videos" && formData[key].trim() === "") {
        newErrors[key] = "Este campo es obligatorio";
        isValid = false;
      }
    });
  
    // Check for offensive language in text fields
    const textFields = ['product', 'description', 'cupon'];
    textFields.forEach(field => {
      if (formData[field] && containsOffensiveLanguage(formData[field])) {
        newErrors[field] = "Por favor, evite usar lenguaje inapropiado";
        isValid = false;
      }
    });
  
    // Existing price validation
    if (!formData.price) {
      newErrors.price = 'El precio es requerido';
      isValid = false;
    } else {
      const price = parseFloat(formData.price);
      if (isNaN(price)) {
        newErrors.price = 'Ingrese un precio válido';
        isValid = false;
      } else if (price <= 0) {
        newErrors.price = 'El precio debe ser mayor a 0';
        isValid = false;
      } else if (price > 999999.99) {
        newErrors.price = 'El precio no puede exceder 999,999.99';
        isValid = false;
      }
    }
  
    // Existing media validation
    if (formData.images.length === 0 && formData.videos.length === 0) {
      newErrors.media = "Debes subir al menos una imagen o un video";
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Check for offensive language as user types
    if (['product', 'description', 'cupon'].includes(name) && containsOffensiveLanguage(value)) {
      setErrors(prev => ({
        ...prev,
        [name]: "Por favor, evite usar lenguaje inapropiado"
      }));
    } else {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePublishOffer = async () => {
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;

    if (!user) {
      console.error("No user is signed in");
      return;
    }

    setLoading(true);

    try {
      const { uid } = user;
      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const { displayName: userName, profileImageUrl: imageProfile, uid: userId } = userData;

        await addDoc(collection(db, "offers"), {
          uid: userId,
          name: userName,
          image: imageProfile,
          product: formData.product,
          offer: formData.offer,
          price: formData.price,
          url: formData.url,
          cupon: formData.cupon,
          description: formData.description,
          images: formData.images,
          videos: formData.videos,
          createdAt: serverTimestamp(),
        });

        setPublishSuccess(true);
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error publishing offer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;

    if (!files) return;

    setLoading(true);

    try {
      const imageUrls = [];

      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVideoUpload = async (event) => {
    const files = event.target.files;

    if (!files) return;

    setLoading(true);

    try {
      const videoUrls = [];

      for (const file of files) {
        const videoUrl = await uploadVideo(file);
        videoUrls.push(videoUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        videos: [...prevFormData.videos, ...videoUrls],
      }));
    } catch (error) {
      console.error("Error uploading videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMedia = (type, index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: prevFormData[type].filter((_, i) => i !== index),
    }));
  };
  const history = useNavigate();
  const handleRedirectHome = () => {
    history("/home");
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    removeButton: {
      display: "block",
      color: "white",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Oferta
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14, mb: 10 }}>
        <TextField
          autoFocus
          margin="dense"
          id="product"
          label="Nombre del producto"
          type="text"
          fullWidth
          required
          name="product"
          value={formData.product}
          onChange={handleInputChange}
          error={!!errors.product}
          helperText={errors.product}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descripción del producto: Hasta 256 caracteres"
          type="text"
          fullWidth
          required
          name="description"
          rows={2}
          inputProps={{
            maxLength: 256
          }}
          multiline
          value={formData.description}
          onChange={handleInputChange}
          error={!!errors.description}
          helperText={errors.description}
        />
        <TextField
  margin="dense"
  id="price"
  label="Precio en USD"
  type="text"
  fullWidth
  required
  name="price"
  value={formData.price}
  onChange={(e) => {
    const value = e.target.value;
    
    // Solo permite números y un punto decimal
    if (/^\d*\.?\d*$/.test(value)) {
      // Si hay punto decimal, limita a 2 decimales
      if (value.includes('.')) {
        const [whole, decimal] = value.split('.');
        const formattedValue = `${whole}.${decimal.slice(0, 2)}`;
        setFormData(prev => ({
          ...prev,
          price: formattedValue
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          price: value
        }));
      }
    }
  }}
  onBlur={(e) => {
    // Al perder el foco, asegura formato con 2 decimales
    const value = e.target.value;
    if (value) {
      const numberValue = parseFloat(value);
      if (!isNaN(numberValue)) {
        setFormData(prev => ({
          ...prev,
          price: numberValue.toFixed(2)
        }));
      }
    }
  }}
  error={!!errors.price}
  helperText={errors.price}
  InputProps={{
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }}
/>
        <TextField
          margin="dense"
          id="offer"
          label="Descuento %"
          type="text"
          fullWidth
          required
          name="offer"
          value={formData.offer}
          onChange={handleInputChange}
          error={!!errors.offer}
          helperText={errors.offer}
        />
        <TextField
          margin="dense"
          id="url"
          label="Ejemplo.com/producto"
          type="text"
          fullWidth
          required
          name="url"
          value={formData.url}
          onChange={handleInputChange}
          error={!!errors.url}
          helperText={errors.url}
        />
        <TextField
          margin="dense"
          id="cupon"
          label="Nombre del cupón de descuento"
          type="text"
          fullWidth
          required
          name="cupon"
          value={formData.cupon}
          onChange={handleInputChange}
          error={!!errors.cupon}
          helperText={errors.cupon}
        />
        <label htmlFor="upload-image-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={{ mt: 2,marginBottom: "20px" }}
            disabled={loading}
            required
          >
            <AddPhotoAlternateIcon sx={{ color: "black" }} />
            Subir Imágenes del producto
            {loading && <CircularProgress size={40} thickness={4} />}
          </Button>
        </label>
        <input
          type="file"
          accept="image/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="upload-image-button"
          multiple
        />

        <label htmlFor="upload-video-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={{ marginBottom: "10px" }}
            disabled={loading}
            required
          >
            <VideoLibraryIcon sx={{ color: "black" }} />
            Subir Video del producto
            {loading && <CircularProgress size={40} thickness={4} />}
          </Button>
        </label>
        <input
          type="file"
          accept="video/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleVideoUpload}
          style={{ display: "none" }}
          id="upload-video-button"
          multiple
        />

        {errors.media && (
          <Typography color="error" variant="caption">
            {errors.media}
          </Typography>
        )}

        <Grid container sx={{ mt: 2 }}>
          {formData.images.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={
                  {
                    /* estilos */
                  }
                }
              >
                <img
                  src={imageUrl}
                  alt="Imagen del producto"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleRemoveMedia("images", index)}
                >
                  ELIMINAR
                </Button>
              </Box>
            </Grid>
          ))}
          {formData.videos.map((videoUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={
                  {
                    /* estilos */
                  }
                }
              >
                <video
                  src={videoUrl}
                  controls
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleRemoveMedia("videos", index)}
                >
                  ELIMINAR
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>La oferta se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Ofertas
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handlePublishOffer}
            color="primary"
            disabled={loading}
          >
            {loading ? "Publicando..." : "Publicar"}
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default PublishOffer;
